import React, { useEffect } from 'react';
import { useState } from "react";
// import LogoImg from '../logo-img/logo-img';
import ScoreSheet from '../score-sheet/score-sheet';
import SettingsTab from '../settings/settings';
import style from './../main-menu/main-menu-style.module.scss';
import { NAV_CONTROL } from '../../javascript/services/globalVars';

function SettingsMenu(Props) {
   const [navUpdate, setNavUpdate] = useState(() => 0);
   const [newMenuState, setNewMenuState] = useState(1);
   const [scoreSheetState, setScoreSheetState] = useState(0);
   const [historyMenuState, setHistoryMenuState] = useState(0);

   const switchTab = (tab) => {
      setNewMenuState(0);
      setScoreSheetState(0);
      setHistoryMenuState(0);
      switch (tab) {
         case '1':
            setScoreSheetState(1);
            break;
         case '2':
            setNewMenuState(1);
            break;
         case '3':
            setHistoryMenuState(1);
            break;
         default:
            break;
      }
   }

   useEffect(() => {
      if (NAV_CONTROL.state.right_menu_tab === 1) {
         switchTab('1');
      } else if (NAV_CONTROL.state.right_menu_tab === 2) {
         switchTab('2');
      } else if (NAV_CONTROL.state.right_menu_tab === 3) {
         switchTab('3');
      }
   }, [navUpdate]);


   useEffect(() => {
      NAV_CONTROL.appendCallback('rightMenu', () => { setNavUpdate(v => v + 1); });
      return () => {
         // >>> Runs on unLoad (Unload runs first if component is already mounted)
      };
   }, []); // >> [] -- Monitor

   const element_head = (
      <div className={style.menuNav}>
         <div className={`${style.navBtn} ${scoreSheetState === 1 ? style.navBtnActive : ''}`} onClick={() => { switchTab('1') }}>Scoresheet</div>
         <div className={`${style.navBtn} ${newMenuState === 1 ? style.navBtnActive : ''}`} onClick={() => { switchTab('2') }}>Settings</div>
         {/* <div className={`${style.navBtn} ${historyMenuState === 1 ? style.navBtnActive : ''}`} onClick={() => { switchTab('3') }}>Cast Settings</div> */}
      </div>
   );
   const element_body = (
      <div className={style.menuWrap}>
         <div className={style.contentWrap}>
            <div className={`${style.tabContent} ${style.tabContent_flexStart} ${scoreSheetState === 1 ? style.tabContentActive : ''}`} >
               <div className={style.tabBanner}></div>
               <ScoreSheet update={scoreSheetState} ></ScoreSheet>
            </div>
            <div className={`${style.tabContent} ${newMenuState === 1 ? style.tabContentActive : ''}`}>
               <div className={style.tabBanner}></div>
               <SettingsTab></SettingsTab>
            </div>
            {/* <div className={`${style.tabContent} ${historyMenuState === 1 ? style.tabContentActive : ''}`}>
               <div className={style.tabBanner}></div>
               Cast Settings
            </div> */}
         </div>
      </div>
   );

   return {
      head: element_head,
      body: element_body  
   }
}

export default SettingsMenu;