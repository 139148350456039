import React, { useState, useEffect } from 'react';
import RemoteImage from '../../remote_img/remote_img';
import { genKeyRan } from '../../../javascript/utils/utils';
import LoadingWheel from '../../loading-wheel/loading-wheel';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import SportLoader from '../../../javascript/sport/sport-loader';
import { build_sme_con } from '../../../javascript/services/auth';
import { API_CONTROL } from '../../../javascript/services/api-connection';
import { Player, Teams, Match_Players } from '../../../javascript/sport/class/coreSport';
import { load_SportLoader_from_db } from '../../../javascript/services/globalVars';
import style from './push-match-box-style.module.scss';

class SettingList {
   single = [];
   pairs = [];
}
class MatchSetting {
   title = '';
   value = '';
   type = '';
   constructor(title = '', value = '', type = '') {
      this.title = title;
      this.value = value;
      this.type = type;
   }
}
class MatchTemplate {
   t_id = 0;
   tc_id = 0;
   load_mid = 0;
   tc_name = '';
   group_name = '';
   match_type = '';
   players = new Match_Players();
   settings = new SettingList();
   match_settings = [];
}



function PushMatchBox(Props) {
   const [showInfo, setShowInfo] = useState(() => false);
   const [matchTemplate, setMatchTemplate] = useState(() => new MatchTemplate());

   useEffect(() => {
      if (typeof Props.match !== 'undefined') {
         prep_match_template(Props.match);
      }
   }, [Props]);

   const get_user_group = (group_id) => {
      return new Promise(async (resolve) => {
         try {
            build_sme_con().then(async (sme_con) => {
               const api_control = API_CONTROL({ sme_con: sme_con });
               api_control.SME.query.user_group(group_id).then((result) => {
                  resolve(result);
               });
            });
         } catch (error) {
            console.error('ERROR - push-match-box - get_user_group: ', error)
            resolve(null);
         }
      });
   }
   const get_player = (user_id) => {
      return new Promise(async (resolve) => {
         try {
            build_sme_con().then(async (sme_con) => {
               const api_control = API_CONTROL({ sme_con: sme_con });
               api_control.SME.query.get_user(user_id).then((result) => {
                  resolve(result);
               });
            });
         } catch (error) {
            console.error('ERROR - push-match-box - get_player: ', error)
            resolve(null);
         }
      });
   }
   const build_player = (player_id, f_name, l_name, color = '', extra_values, map_link = null, loaded_match = null) => {
      return new Promise(async (resolve) => {
         let player_replace_id = null;
         let player_replace_name = null;

         if (typeof extra_values === 'object') {
            for (let a = 0; a < extra_values.length; a++) {
               if (extra_values[a].data_type === 'player_replace_id') { player_replace_id = extra_values[a].value !== 0?extra_values[a].value:null; }
               if (extra_values[a].data_type === 'player_replace_name') { player_replace_name = extra_values[a].value !== ''?extra_values[a].value:null; }
            }
         }

         if (player_replace_id !== null) { player_id = player_replace_id; }

         if (parseInt(player_id) > 0) {
            const player_data = await get_player(player_id);
            const user_id = player_replace_id !== null? player_replace_id:player_data !== null ? player_data.user_id : 0;
            const player_l_name = player_replace_name !== null? '': player_data !== null ? player_data.user_lname : l_name;
            const player_f_name = player_replace_name !== null? player_replace_name: player_data !== null ? player_data.user_fname : f_name;
            const display_name = player_replace_name !== null? player_replace_name:player_data !== null ? player_data.user_fname + ' ' + player_data.user_lname : f_name + ' ' + l_name;
            const player = new Player(
               {
                  color: color,
                  id: user_id,
                  img: player_data !== null ? player_data.user_img : 'images/clipart/_Default.png',
                  first_name: player_f_name,
                  last_name: player_l_name,
                  display_name: display_name,
               }
            );
            resolve(player);
         } else {
            const link_param = map_link[1];
            if (player_id.indexOf('/') > 0) {
               const name_list = player_id.split('/');
               if (link_param === 'player_a_id' || link_param === 'player_b_id') {
                  player_id = name_list[0].trim();
               } else if (link_param === 'player_c_id' || link_param === 'player_d_id') {
                  player_id = name_list[1].trim();
               }
            }

            console.log('player_id: ', player_id);


            const player = new Player(
               {
                  color: color,
                  id: 0,
                  img: 'images/clipart/_Default.png',
                  first_name: player_id,
                  last_name: '',
                  display_name: player_id,
               }
            );
            resolve(player);
         }
      });
   }
   const build_match_settings = (match) => {
      let match_type = '';
      if (match.match_type === 'TM') {
         match_type = 'Singles';
      } else if (match.match_type === 'TD') {
         match_type = 'Doubles';
      } else if (match.match_type === 'TMC') {
         match_type = 'Singles';
         if (match.player_a_id.indexOf('/') > 0) { match_type = 'Doubles'; }
         else if (match.player_b_id.indexOf('/') > 0) { match_type = 'Doubles'; }
      }

      const point_per_game = match.game_points;
      const games_per_match = match.best_of;
      const win_by = match.win_by === '1d' ? 1 : 2;
      const settings = new SettingList();

      settings.single.push(new MatchSetting('Match Code', match.match_code));
      // settings.single.push(new MatchSetting('Match Time', match.match_time));
      settings.pairs.push([new MatchSetting('Match Type', match_type), new MatchSetting('Point per Game', point_per_game)]);
      settings.pairs.push([new MatchSetting('Games per Match', games_per_match), new MatchSetting('Win By', win_by)]);

      return settings;
   }

   const Data_Map_Helper = () => {
      const get_value_from_data_map = (data_map, map_link, convert_table) => {
         let data_map_link = JSON.parse(JSON.stringify(data_map));
         for (let a = 0; a < map_link.length; a++) { data_map_link = data_map_link[map_link[a]]; }
         if (typeof convert_table !== 'undefined') {
            const index = convert_table.findIndex(i => i.value === parseInt(data_map_link));
            if (index !== -1) {
               data_map_link = convert_table[index].new_val;
            }
         }
         return data_map_link;
      }

      const fun_match_type = (value, loaded_match = null) => {
         let result = '';
         if (value === 'TM') {
            // result = 'Singles';
            result = 1;
         } else if (value === 'TD') {
            // result = 'Doubles';
            result = 2;
         } else if (value === 'TMC') {
            // console.log('loaded_match: ', loaded_match);
            // result = 'Singles';
            result = 1;
            if (loaded_match !== null &&loaded_match.player_a_id.indexOf('/') > 0) {
               // result = 'Doubles'; 
               result = 2;
            } else if (loaded_match !== null && loaded_match.player_b_id.indexOf('/') > 0) {
               // result = 'Doubles'; 
               result = 2;
            }
         }
         return result;
      }

      const fun_get_player = (value, sub_value, extra_values, map_link = null, loaded_match = null) => {
         return new Promise(async (resolve) => {
            if (value === '0' || value === '') {
               if (map_link !== null && loaded_match !== null && map_link[1] === 'player_c_id' && loaded_match.player_a_id.indexOf('/') > 0) {
                  value = loaded_match.player_a_id;
               } else if (map_link !== null && loaded_match !== null && map_link[1] === 'player_d_id' && loaded_match.player_b_id.indexOf('/') > 0) {
                  value = loaded_match.player_b_id;
               }
            }

            if (value !== '0' && value !== '') {
               const color = typeof sub_value !== 'undefined' ? sub_value.value : '';
               const player = await build_player(value, 'Player', '', color, extra_values, map_link, loaded_match);
               resolve(player);
            } else {
               resolve(null);
            }
         });
      }

      const fun_win_by = (value) => {
         return value === '1d' ? 1 : 2;
      }
      const exe_fun = (fun, value, sub_value, extra_values, map_link = null, loaded_match = null) => {
         switch (fun) {
            case 'match_type': return fun_match_type(value, loaded_match);
            case 'get_player': return fun_get_player(value, sub_value, extra_values, map_link, loaded_match);
            case 'win_by': return fun_win_by(value);
            default:
               console.error(`ERROR - Data_Map_Helper - exe_fun function: "${fun}" is not implemented!`);
               return value;
         }
      }

      const get_result = (data_map, map_link, fun, sub_value, convert_table, extra_values) => {
         return new Promise(async (resolve) => {
            try {
               const raw_value = get_value_from_data_map(data_map, map_link, convert_table);
               const extra_value_list = [];
               if (typeof extra_values === 'object') {
                  for (let a = 0; a < extra_values.length; a++) {
                     if (extra_values[a].type === 'mapped') {
                        const raw_value = get_value_from_data_map(data_map, extra_values[a].value);
                        extra_value_list.push({
                           data_type: extra_values[a].data_type,
                           value: raw_value
                        })
                     }
                  }
               }

               const result = typeof fun !== 'undefined' && fun !== null ? await exe_fun(fun, raw_value, sub_value, extra_value_list, map_link, data_map.loaded_match) : raw_value;
               resolve(result);
            } catch (error) {
               console.error('ERROR - get_result: ', error);
               resolve('');
            }
         });
      }

      return {
         get_value_from_data_map,
         exe_fun,
         get_result,
      }
   }


   const map_push_match_settings = async (data_map) => {
      // console.error('>>>-- map_push_match_settings --<<<');
      // console.log('map_push_match_settings: ', data_map);
      try {
         const settings = [];
         const TMP_SPORT_BASE = await load_SportLoader_from_db(true);
         const sport_settings = TMP_SPORT_BASE.sport_object.get_settings();
         const data_map_helper = Data_Map_Helper();
         const setting_list = [];
         TMP_SPORT_BASE.sport_object.map(sport_settings, (setting) => { setting_list.push(setting); });
         for (let a = 0; a < setting_list.length; a++) {
            const setting = setting_list[a];
            if (typeof setting.value.data_link !== 'undefined') {
               let linked_value = null;
               if (setting.value.data_link.type === 'default') {
                  linked_value = setting.value.data_link.value;
               } else if (setting.value.data_link.type === 'mapped') {
                  const fun = setting.value.data_link.fun;
                  const map_link = setting.value.data_link.value;
                  // console.log('test->>>>>>>>>>>>>>>>>>>: ', setting.value.data_link.sub_values);
                  linked_value = await data_map_helper.get_result(data_map, map_link, fun, setting.value.sub_value, setting.value.data_link.convert_table, setting.value.data_link.sub_values);
                  // console.log('linked_value: ', linked_value);
                  if (typeof setting.value.data_link.default !== 'undefined' && linked_value === '') {
                     linked_value = setting.value.data_link.default;
                  }
               }
               if (linked_value !== null) {
                  const new_setting = new MatchSetting(setting.value.name, linked_value, setting.value.data_link.data_type);
                  settings.push(new_setting);
               }
            } else { console.error('Missing data_link: ', setting.value.name); }
         }
         // console.log('map_push_match_settings - result: ', settings);
         return settings;
      } catch (error) {
         console.error('ERROR - map_push_match_settings: ', error);
      }
   }

   const prep_match_template = (match) => {
      return new Promise(async (resolve) => {
         try {
            const data_map = {
               loaded_match: match,
            }

            const match_template = new MatchTemplate();
            const display_settings = build_match_settings(match);
            const user_group = await get_user_group(match.t_id);
            const match_settings = await map_push_match_settings(data_map);
            match_template.t_id = match.t_id;
            match_template.tc_id = match.tc_id;
            match_template.tc_name = match.tc_name;
            match_template.load_mid = match.loaded_mid;
            match_template.match_code = match.match_code;
            match_template.match_type = match.match_type;
            match_template.group_name = user_group !== null ? user_group.t_name : 'Custom Match';
            match_template.settings = display_settings;
            match_template.match_settings = match_settings;

            // console.warn('>>> match: ', match);
            // console.warn('>>> settings: ', settings);
            // console.warn('>>> user_group: ', user_group);
            // console.warn('match_settings: ', match_settings);

            // override_code_1
            // override_code_2
            // override_code_3
            // override_code_4
            // override_name_1
            // override_name_2
            // override_name_3
            // override_name_4
            // console.log('X: ', match.override_name_1);


            const extract_value = (link) => {
               const index = match_settings.findIndex(i => i.title === link);
               if (index !== -1) {
                  return match_settings[index].value;
               }
               return null;
            }

            // TODO: Added player over_ride support
            const team_a_tmp = [];
            const team_b_tmp = [];

            const player_a = extract_value('player_a');
            if (player_a !== null) { 
               // console.log('AA: ', match.override_name_1);
               // console.log('player_a--: ', player_a);
               // if (match.override_name_1 !== "") {
               //    player_a.display_name = match.override_name_1;
               // }
               team_a_tmp.push(player_a); 
            }



            const player_c = extract_value('player_c');
            if (player_c !== null) { team_a_tmp.push(player_c); }

            const player_b = extract_value('player_b');
            if (player_b !== null) { team_b_tmp.push(player_b); }

            const player_d = extract_value('player_d');
            if (player_d !== null) { team_b_tmp.push(player_d); }

            const team_a = new Teams({ players: team_a_tmp });
            const team_b = new Teams({ players: team_b_tmp });
            const match_players = new Match_Players({ teams: [team_a, team_b] });
            match_template.players = match_players;

            // console.warn('match_template: ', match_template);
            setMatchTemplate(match_template);

         } catch (error) {
            console.error('ERROR - prep_match_template: ', error);
         }
      });
   }
 
   const on_click = (event, loaded_id) => {
      const target_id = event.target.id;
      const target_parent_id = event.target.parentElement.id;
      const info_id_list = ['infoBtn', 'infoBtnIcon'];

      if (info_id_list.includes(target_id) || info_id_list.includes(target_parent_id)) {
         if (showInfo) { setShowInfo(false); }
         else { setShowInfo(true); }
      } else if (typeof Props.onClick === 'function') {
         Props.onClick(JSON.parse(JSON.stringify(matchTemplate)));
      }
   }

   return (
      <div className={style.match_wrap} onClick={(e) => { on_click(e, matchTemplate.load_mid) }}>
         {matchTemplate.load_mid === 0 ? (<div className={style.loader}><LoadingWheel show={true} /></div>) : null}
         <div id="infoBtn" className={style.info_icon_wrap}> <FontAwesomeIcon id="infoBtnIcon" icon={faCircleInfo} /> </div>
         {matchTemplate.tc_name !== '' ?
            (<>
               <div className={style.match_title}>TOURNAMENT: {matchTemplate.tc_name}</div>
               {/* <div className={`${style.match_title} ${style.match_title_flat}`} > DIVISION:  {matchTemplate.group_name}  </div> */}
            </>)
            :null
         }
         <div className={style.match_player_wrap}>
            <div className={style.match_player_block} key={genKeyRan('playerBlock')}>
               {matchTemplate.players.teams[0].players.map(player => (<div key={genKeyRan('player')} className={style.match_player}>  <RemoteImage src={player.img} />  <span> {player.display_name} </span> </div>))}
            </div>
            <div className={style.match_player_divider}> VS. </div>
            <div className={style.match_player_block} key={genKeyRan('playerBlock')}>
               {matchTemplate.players.teams[1].players.map(player => (<div key={genKeyRan('player')} className={style.match_player}>  <RemoteImage src={player.img} />  <span> {player.display_name} </span> </div>))}
            </div>
         </div>
         {showInfo ? (
            <div className={style.match_settings_wrap} >
               {matchTemplate.settings.single.map(setting => (
                  <div className={style.match_settings_block} key={genKeyRan('settingBlock')}>
                     <div key={setting.title} className={style.match_settings_item_full}> <span>{setting.title}:</span> {setting.value} </div>
                  </div>
               ))}
               {matchTemplate.settings.pairs.map((pair, item) =>
               (<div key={'pair-' + item} className={style.match_settings_block}>
                  {pair.map(setting => (
                     <div key={setting.title} className={style.match_settings_item}> <span>{setting.title}:</span> {setting.value} </div>
                  ))}
               </div>
               ))}
            </div>
         ) : null}
         <div className={`${style.match_title} ${style.match_title_bottom}`} > DIVISION:  {matchTemplate.group_name}  </div>
      </div>
   );
}

export default PushMatchBox;