import React, { useMemo, useState } from 'react';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { speech } from '../../../../javascript/sport/sport-function';
import { SPORT_BASE, NAV_CONTROL } from '../../../../javascript/services/globalVars';
import style from './serving-indicator.style.module.scss';

function ServingIndicator(Props) {
   const default_color = { color: '#7b7b7b4d' };
   const default_side_color = { color: '#FFF' };
   const [serverSide, setServerSide] = useState(() => '');
   const [sideColor, setSideColor] = useState(() => default_side_color);
   const [singleHelper, setSingleHelper] = useState(() => false);
   const [doublesHelper, setDoublesHelper] = useState(() => false);
   const [singleHelperJSX, setSingleHelperJSX] = useState('');
   const [doublesHelperJSX, setDoublesHelperJSX] = useState('');
   const [servingSide, setServingSide] = useState(() => 1);
   const [doublesHelperType, setDoublesHelperType] = useState(() => 1);
   const [showDoublesHelper, setShowDoublesHelper] = useState(() => false);

   const updateServingSate = (servingState) => {
      if (typeof servingState !== 'undefined') {
         let setColor;
         let serverSide = '';
         let serverColor1 = default_color;
         let serverColor2 = default_color;

         if (servingState.side === Props.pos) {
            setShowDoublesHelper(true);
            if (typeof Props.colorTable !== 'undefined') {
               if (Props.pos === 1) {
                  if (servingState.side_state_1.serving_player === 1) {
                     setColor = Props.colorTable[0];
                     serverColor1 = { color: setColor };
                  } else if (servingState.side_state_1.serving_player === 2) {
                     setColor = Props.colorTable[2];
                     serverColor2 = { color: setColor };
                  }
               } else if (Props.pos === 2) {
                  if (servingState.side_state_2.serving_player === 1) {
                     setColor = Props.colorTable[1];
                     serverColor1 = { color: setColor };
                  } else if (servingState.side_state_2.serving_player === 2) {
                     setColor = Props.colorTable[3];
                     serverColor2 = { color: setColor };
                  }
               }

               if (doublesHelperType === 2) {
                  if (Props.pos === 1) {
                     setSideColor({ color: "var(--player-a-color)" });
                  } else if (Props.pos === 2) {
                     setSideColor({ color: "var(--player-b-color)" });
                  }
               } else {
                  setSideColor({ color: setColor });
               }

               serverSide = servingState.inner_side === 1 ? 'L' : 'R';
            }
         } else {
            setShowDoublesHelper(false);
         }

         if (typeof Props.servingState.settings.doubles_helper !== 'undefined' &&
            Props.servingState.settings.doubles_helper.value === true &&
            servingState.settings.doubles_helper.type === 'initial' &&
            Props.servingState.type === 'doubles'
         ) {
            const players = SPORT_BASE.sport_object.scoring.state.match.players;
            if (typeof players.teams[Props.pos - 1].players[0] !== 'undefined') {
               const initial = players.teams[Props.pos - 1].players[0].initial;
               setSingleHelperJSX(<div style={serverColor1}> {initial} </div>);
            }
            if (typeof players.teams[Props.pos - 1].players[1] !== 'undefined') {
               const initial = players.teams[Props.pos - 1].players[1].initial;



               // console.log('players.teams: ', players.teams);

               setDoublesHelperJSX(<div style={serverColor2}> {initial} </div>);
            }
         } else {
            setSingleHelperJSX(<FontAwesomeIcon style={serverColor1} icon={faUser} />);
            setDoublesHelperJSX(<FontAwesomeIcon style={serverColor2} icon={faUser} />);
         }
         setServerSide(serverSide);
      }
   }
   useMemo(() => {
      setSingleHelper(false);
      setDoublesHelper(false);
      const serving_side = Props.pos === 1 ? Props.servingState.side_state_1.serving_side : Props.servingState.side_state_2.serving_side;
      setServingSide(serving_side);

      // console.log('SPORT_BASE.sport_object.scoring.serving_type: ', SPORT_BASE.sport_object.scoring.serving_type)

      if (SPORT_BASE.sport_object.scoring.serving_type === 'pickleball') {
         const game_options = SPORT_BASE.sport_object.scoring.get_setting({ setting_name: 'game_options' });
         if (game_options.value === 1 && SPORT_BASE.sport_object.scoring.state.serving.type === 'doubles') {
            setDoublesHelperType(2);
         } else {
            setDoublesHelperType(1);
         }
      } else {
         setDoublesHelperType(1);
      }
      if (typeof Props.servingState.settings.single_helper !== 'undefined') {
         setSingleHelper(Props.servingState.settings.single_helper.value);
      }


      // console.log('Props.servingState.settings.doubles_helper: ', Props.servingState.settings.doubles_helper.value)

      if (typeof Props.servingState.settings.doubles_helper !== 'undefined' && Props.servingState.settings.doubles_helper.value === true && Props.servingState.type === 'doubles') {
         setSingleHelper(true);
         setDoublesHelper(true);
      }
      return updateServingSate(Props.servingState);
   }, [Props.servingState, Props.update])

   const changeInnerSide = async (innerSide) => {
      if (SPORT_BASE.sport_object.scoring.state.serving.player === Props.pos && SPORT_BASE.sport_object.scoring.state.serving.inner_side === innerSide) {
         if (innerSide === 1) {
            speech('Left side', false);
         } else if (innerSide === 2) {
            speech('Right side', false);
         }
      }

      SPORT_BASE.sport_object.scoring.state.serving.updateServer(Props.pos, innerSide);
      await SPORT_BASE.saveGameState();
      await SPORT_BASE.updateGameStateTimestamp();
      NAV_CONTROL.refreshScore();
   }

   const changeServeIndicator = async (player) => {
      if (Props.pos === 1 && player === 2) {
         player = 3;
      } else if (Props.pos === 2 && player === 1) {
         player = 2;
      } else if (Props.pos === 2 && player === 2) {
         player = 4;
      }
      SPORT_BASE.sport_object.scoring.state.serving.updatePlayer(player);
      await SPORT_BASE.saveGameState();
      await SPORT_BASE.updateGameStateTimestamp();
      NAV_CONTROL.refreshScore();
   }

   return (
      <div className={style.scoreServe}>
         <div onClick={() => { changeInnerSide(1) }} style={serverSide === 'L' ? sideColor : {}} className={`${style.serveSide} ${serverSide === 'L' ? style.serveSideActive : ''}`}>L</div>
         <div className={style.serveIndicatorWrap} >
            {doublesHelperType === 1 ? (
               <>
               {singleHelper ? <div onClick={() => { changeServeIndicator(1) }} className={style.serveIndicator}> {singleHelperJSX} </div> : null}
               {doublesHelper ? <div onClick={() => { changeServeIndicator(2) }} className={style.serveIndicator}> {doublesHelperJSX} </div> : null}
               </>
            ): (
               <>
               {showDoublesHelper ? (
                  <>
                  {servingSide === 1 ? (
                     <div onClick={() => { changeServeIndicator(2) }} className={style.serveIndicator}> 1 </div>
                  ) : (
                     <div onClick={() => { changeServeIndicator(1) }} className={style.serveIndicator}> 2 </div>
                  )}
                  </>
               ) : null}
               </>
            )}
         </div>
         <div onClick={() => { changeInnerSide(2) }} style={serverSide === 'R' ? sideColor : {}} className={`${style.serveSide} ${serverSide === 'R' ? style.serveSideActive : ''}`}>R</div>
      </div>
   );
}

export default ServingIndicator;