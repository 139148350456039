import { Sport, SportSetting, string_to_icon, Match_State, Warmup_State, Between_Games_State, Score_Settings, Clock_Settings } from "./coreSport";
import ToolButton from "../../../components/tool-button/tool-button";
import { build_sport_function, free_app_status } from "../sport-function";
import DBHandler from "../../index-db/db-handler";
import { SETTINGS, SPORT_BASE } from "../../services/globalVars";
import { genKeyRan } from "../../utils/utils";
import Auth from "../../services/auth";


class CreateSport extends Sport {
   warmup;
   scoring;
   settings;
   app_state;
   game_menu;
   sport_name;
   game_state;
   sport_initial;
   between_games;
   clocks = [];
   tool_blocks = {};

   constructor(sportObj) { // constructor
      super();
      this.init_self(sportObj);
   }

   load_app_state = () => {
      return new Promise(async (resolve) => {
         const dbHandler = DBHandler();
         this.app_state = await dbHandler.load_app_state();
         resolve(true);
      });
   }

   init_self = (sportObj) => {
      this.sport_name = sportObj.sport_name;
      this.sport_initial = sportObj.sport_initial;
      this.init_self_settings(sportObj.settings);
      this.init_self_game_menu(sportObj.game_menu);
      this.init_self_setting_menu(sportObj.settings_menu);
      this.init_self_scoring(sportObj.scoring, sportObj.settings, sportObj.setup);
      this.init_self_warmup(sportObj.warmup);
      this.init_self_between_games(sportObj.between_games);
      this.init_self_clocks(sportObj.clocks);
   }

   init_self_settings = (settings) => {
      this.settings = this.build_config(settings, this);
   }

   init_self_game_menu = (menu_settings) => {
      const tool_block = this.build_tools_2(menu_settings.tool_block);
      this.tool_blocks.game_menu = tool_block;
   }
   init_self_setting_menu = (menu_settings) => {
      // const tool_block = this.build_tools_2(menu_settings.tool_block);
      // this.tool_blocks.settings_menu = tool_block;
      this.tool_blocks.settings_menu = menu_settings.tool_block;
      // console.log('>>> init_self_setting_menu <<<');
      // this.get_tool_block('settings_menu');
   }

   init_self_scoring = (scoreSettings, config, setup) => {
      const settings = this.get_score_settings();
      const state = this.init_scoring_state(scoreSettings, config, setup);
      const tool_block = this.build_tools(scoreSettings.tool_block);
      this.tool_blocks.scoring = tool_block;
      this.scoring = new Score_Settings({
         scoring_type: scoreSettings.scoring_type,
         serving_type: scoreSettings.serving_type,
         serving_config: scoreSettings.serving,
         side: scoreSettings.sides,
         state: state,
         settings: settings,
      });
   }

   init_self_clocks = (clockSetting) => {
      this.clocks = [];
      if (typeof clockSetting !== 'undefined') {
         for (let a = 0; a < clockSetting.length; a++) {
            const clockTest = new Clock_Settings(clockSetting[a]);
            this.clocks.push(clockTest);
         }
      }
   }

   init_self_warmup = (warmupSettings) => {
      const tool_block = this.build_tools(warmupSettings.tool_block);
      this.warmup = {};
      this.warmup.state = new Warmup_State();
      this.warmup.tool_block = tool_block;
   }

   init_self_between_games = (settings) => {
      this.between_games = new Between_Games_State({ setup: settings.setup });
      this.between_games.tool_block = this.build_tools(settings.tool_block);
   }

   init_scoring_state = (settings, config, setup) => {
      const serving_config = settings.serving;
      const game_state = new Match_State({ serving_config: serving_config, sport_config: config, parent: this.scoring });
      return game_state;
   }

   load_scoring_state = (scoring_state) => {
      scoring_state.parent = this.scoring;
      const loaded_settings = this.get_score_settings();
      this.scoring = new Score_Settings({
         scoring_type: this.scoring.scoring_type,
         serving_type: this.scoring.serving_type,
         serving_config: this.scoring.serving.config,
         side: this.scoring.side,
         state: new Match_State(scoring_state),
         // settings: this.scoring.settings,
         settings: loaded_settings,
      });
   }

   build_config = (json, parentElm = null) => {
      const results = {};
      for (const key in json) {
         if (Object.hasOwnProperty.call(json, key)) {
            if (typeof json[key].group !== 'undefined') {
               results[key] = { group: '' };
               results[key].group = this.build_config(json[key].group, parentElm);
            } else {
               json[key].parent = parentElm;
               results[key] = new SportSetting(json[key]);
            }
         }
      }
      return results;
   }

   build_tools = (tool_block) => { // This is to be duplicated
      const tools = [];
      for (let a = 0; a < tool_block.length; a++) {
         let jsx = tool_block[a].jsx;
         if (typeof tool_block[a].jsx.length === 'number') {
            jsx = tool_block[a].jsx[0];
         }
         const icon = string_to_icon(jsx.icon);
         tools.push({
            pos: tool_block[a].pos,
            hidden: tool_block[a].hidden,
            dep: tool_block[a].dep,
            jsx: (<div className={jsx.class} >{icon}{jsx.text}</div>),
            fun: build_sport_function(tool_block[a].fun)
         });
      }
      return tools;
   }

   build_tools_2 = (tool_block) => {
      const tools = [];


      for (let a = 0; a < tool_block.length; a++) {
         tools.push((<ToolButton key={genKeyRan()} options={tool_block[a]} />));
      }
      return tools;
   }

   map = (object, callback) => {
      for (const key in object) {
         if (Object.hasOwnProperty.call(object, key)) {
            if (typeof object[key].group !== 'undefined') {
               this.map(object[key].group, (item) => {
                  callback(item);
               });
            } else {
               callback({ key: key, value: object[key] })
            }
         }
      }
   }

   run_element_fun = (elementObj, funObj) => {
      switch (funObj.type) {
         case 'set_default':
            for (let a = 0; a < funObj.value.length; a++) {
               if (typeof funObj.value[a].setting !== 'undefined') {
                  const setting = funObj.value[a].setting;
                  const value = funObj.value[a].value;
                  const default_value = JSON.parse(JSON.stringify(funObj.value[a].default));
                  if (elementObj.value.value === value) {
                     if (typeof this.settings[setting] === 'object') {
                        this.settings[setting].value = default_value;
                     }
                  }
               }
            }
            break;
      }
   }

   get_tool_block = (toolType) => {
      return new Promise(async (resolve) => {
         const result = [];
         await this.load_app_state();
         let tools = [];
         if (toolType === 'between_games') {
            tools = this.between_games.tool_block;
         } else if (toolType === 'scoring') {
            tools = this.tool_blocks.scoring;
         } else if (toolType === 'settings_menu') {
            tools = this.tool_blocks.settings_menu;
         }

         for (let a = 0; a < tools.length; a++) {
            let loadTool = true;
            if (typeof tools[a].dep !== 'undefined') {
               loadTool = await this.check_dependencies(tools[a].dep);

               // for (let b = 0; b < tools[a].dep.length; b++) {
               //    if (tools[a].dep[b].type === 'game_state') {
               //       if (tools[a].dep[b].value !== this.app_state.game_state) { loadTool = false; }
               //    } else if (tools[a].dep[b].type === 'team_1_score_not') {
               //       if (tools[a].dep[b].value !== this.scoring.state.match.active_game.team_1_score) { loadTool = false; }
               //    } else if (tools[a].dep[b].type === 'team_2_score_not') {
               //       if (tools[a].dep[b].value !== this.scoring.state.match.active_game.team_2_score) { loadTool = false; }
               //    } else if (tools[a].dep[b].type === 'team_1_score_greater') {
               //       if (tools[a].dep[b].value > this.scoring.state.match.active_game.team_1_score) { loadTool = false; }
               //    } else if (tools[a].dep[b].type === 'team_2_score_greater') {
               //       if (tools[a].dep[b].value > this.scoring.state.match.active_game.team_2_score) { loadTool = false; }
               //    } else if (tools[a].dep[b].type === 'serve_inner_changed') {
               //       if (tools[a].dep[b].value !== this.scoring.state.serving.manual_change) { loadTool = false; }
               //    } else if (tools[a].dep[b].type === 'game_count') {
               //       if (tools[a].dep[b].value !== this.scoring.state.match.games.length) { loadTool = false; }
               //    } else if (tools[a].dep[b].type === 'continuous_10_points' && loadTool) {
               //       const team_1_score = SPORT_BASE.sport_object.scoring.state.match.active_game.team_1_score;
               //       const team_2_score = SPORT_BASE.sport_object.scoring.state.match.active_game.team_2_score;
               //       // >>> check if there is a difference is less than 10
               //       if (!(Math.abs(team_1_score - team_2_score) >= 10)) { loadTool = false; }
               //    } else if (tools[a].dep[b].type === 'account_logged_in') {
               //       const authObj = Auth();
               //       const token = await authObj.get_token();
               //       if (tools[a].dep[b].value !== (token.token !== null)) { loadTool = false; }
               //    } else if (tools[a].dep[b].type === 'game_settings') {
               //       const settingType = tools[a].dep[b].value.type;
               //       const settingValue = tools[a].dep[b].value.value;
               //       const setting = this.get_setting_by_key(settingType);
               //       if (settingValue !== setting.value) { loadTool = false; }
               //    } else if (tools[a].dep[b].type === 'clock') {
               //       const clockName = tools[a].dep[b].value.type;
               //       const clockValue = tools[a].dep[b].value.value;
               //       const clock = SPORT_BASE.getClock(clockName);
               //       if (clockValue !== clock.value) { loadTool = false; }
               //    } else if (tools[a].dep[b].type === 'has_log') {
               //       const index = SPORT_BASE.sport_object.scoring.state.match.active_game.logs.findIndex(i => i.content === 'swap-score');
               //       if (index === -1) { loadTool = false; }
               //    } else if (tools[a].dep[b].type === 'current_game_total_logs_greater') {
               //       const total_logs = SPORT_BASE.sport_object.scoring.state.match.active_game.logs.length;
               //       if (tools[a].dep[b].value >= total_logs) { loadTool = false; }
               //    } else if (tools[a].dep[b].type === 'current_game_total_logs') {
               //       const total_logs = SPORT_BASE.sport_object.scoring.state.match.active_game.logs.length;
               //       if (tools[a].dep[b].value !== total_logs) { loadTool = false; }
               //    }
               // }
            }
            if (loadTool) {
               result.push(tools[a]);
            }
         }
         resolve(result);
      })
   }

   check_dependencies = (dependencies) => {
      return new Promise(async (resolve) => {
         let result = true;
         // console.log('dependencies: ', dependencies);
         for (let b = 0; b < dependencies.length; b++) {
            if (dependencies[b].type === 'game_state') {
               if (dependencies[b].value !== this.app_state.game_state) { result = false; }
            } else if (dependencies[b].type === 'team_1_score_not') {
               if (dependencies[b].value !== this.scoring.state.match.active_game.team_1_score) { result = false; }
            } else if (dependencies[b].type === 'team_2_score_not') {
               if (dependencies[b].value !== this.scoring.state.match.active_game.team_2_score) { result = false; }
            } else if (dependencies[b].type === 'team_1_score_greater') {
               if (dependencies[b].value > this.scoring.state.match.active_game.team_1_score) { result = false; }
            } else if (dependencies[b].type === 'team_2_score_greater') {
               if (dependencies[b].value > this.scoring.state.match.active_game.team_2_score) { result = false; }
            } else if (dependencies[b].type === 'serve_inner_changed') {
               if (dependencies[b].value !== this.scoring.state.serving.manual_change) { result = false; }
            } else if (dependencies[b].type === 'game_count') {
               if (dependencies[b].value !== this.scoring.state.match.games.length) { result = false; }
            } else if (dependencies[b].type === 'continuous_10_points' && result) {
               const team_1_score = SPORT_BASE.sport_object.scoring.state.match.active_game.team_1_score;
               const team_2_score = SPORT_BASE.sport_object.scoring.state.match.active_game.team_2_score;
               // >>> check if there is a difference is less than 10
               if (!(Math.abs(team_1_score - team_2_score) >= 10)) { result = false; }
            } else if (dependencies[b].type === 'account_logged_in') {
               const authObj = Auth();
               const token = await authObj.get_token();
               if (dependencies[b].value !== (token.token !== null)) { result = false; }
            } else if (dependencies[b].type === 'game_settings') {
               const settingType = dependencies[b].value.type;
               const settingValue = dependencies[b].value.value;
               const setting = this.get_setting_by_key(settingType);
               if (settingValue !== setting.value) { result = false; }
            } else if (dependencies[b].type === 'clock') {
               const clockName = dependencies[b].value.type;
               const clockValue = dependencies[b].value.value;
               const clock = SPORT_BASE.getClock(clockName);
               if (clockValue !== clock.value) { result = false; }
            } else if (dependencies[b].type === 'has_log') {
               const index = SPORT_BASE.sport_object.scoring.state.match.active_game.logs.findIndex(i => i.content === 'swap-score');
               if (index === -1) { result = false; }
            } else if (dependencies[b].type === 'current_game_total_logs_greater') {
               const total_logs = SPORT_BASE.sport_object.scoring.state.match.active_game.logs.length;
               if (dependencies[b].value >= total_logs) { result = false; }
            } else if (dependencies[b].type === 'current_game_total_logs') {
               const total_logs = SPORT_BASE.sport_object.scoring.state.match.active_game.logs.length;
               if (dependencies[b].value !== total_logs) { result = false; }
            } else {
               // console.error("check_dependencies type not found: ", dependencies[b].type);
            }
         }
   
         resolve(result);
      });
   }



   check_sport_setting_dependencies = (sportSetting) => {
      const result = { result: true }
      if (typeof sportSetting.dep !== 'undefined') {
         for (let a = 0; a < sportSetting.dep.length; a++) {
            // for (const key in sportSetting.dep[a]) {
            if (typeof sportSetting.dep[a].type !== 'undefined') {
               switch (sportSetting.dep[a].type) {
                  case 'settings_mute':
                     if (SETTINGS.mute === sportSetting.dep[a].value) {
                        result.result = true;
                     } else {
                        result.result = false;
                        return result;
                     }
                     break;
                  case 'free_app':
                     const free_app_status_result = free_app_status();
                     if (sportSetting.dep[a].value == free_app_status_result) {
                        result.result = true;
                     } else {
                        result.result = false;
                        return result;
                     }
                     break;
                  case 'game_settings':
                     result.result = false;
                     if (typeof sportSetting.dep[a].sub_type !== 'undefined' && typeof this.scoring.settings !== 'undefined') {
                        for (let i = 0; i < this.scoring.settings.length; i++) {
                           if (this.scoring.settings[i].name === sportSetting.dep[a].sub_type && this.scoring.settings[i].value === sportSetting.dep[a].value) {
                              result.result = true;
                              break;
                           }
                        }
                     } else {
                        result.result = false;
                        return result;
                     }
                     break;
                  case 'game_setting_is_greater':
                     result.result = false;
                     if (typeof sportSetting.dep[a].sub_type !== 'undefined' && typeof this.scoring.settings !== 'undefined') {
                        for (let i = 0; i < this.scoring.settings.length; i++) {
                           if (this.scoring.settings[i].name === sportSetting.dep[a].sub_type && this.scoring.settings[i].value > sportSetting.dep[a].value) {
                              result.result = true;
                              break;
                           }
                        }
                     } else {
                        result.result = false;
                        return result;
                     }
                     break;
                  default:
                     console.error("check_sport_setting_dependencies type not found: ", sportSetting.dep[a].type);
                     result.result = false;
                     return result;
                     break;
               }
            } else {
               for (const key in sportSetting.dep[a]) {
                  if (Object.hasOwnProperty.call(sportSetting.dep[a], key)) {
                     const dep_value = sportSetting.dep[a][key];
                     const set_value = this.settings[key].value;
                     // set_value = this.settings[key].value;
                     if (dep_value !== set_value) {
                        result.result = false;
                        return result;
                     }
                  }
               }
            }
         }
      }
      return result;
   }

   get_setting_by_key = (key) => {
      let result = null;
      this.map(this.settings, (item) => {
         // console.log('item.key: ', item.key)
         if (item.key === key) {
            result = item.value;
         } else if (typeof item.value.sub_value !== 'undefined' && item.value.sub_value.name === key) {
            result = item.value.sub_value;
         }
      });
      return result;
   }

   get_settings = () => {
      // console.log('get_settings: ', this.settings);
      let result = [];
      this.map(this.settings, (item) => {
         result.push(item.value);
      });
      return result;
   }

   get_player_settings = () => {
      const players_Obj = this.scoring.state.match.players;
      return {
         Match_Players: players_Obj,
      };
   }

   get_score_settings = () => {
      const result = [];
      this.map(this.settings, (item) => {
         const dep_check = this.check_sport_setting_dependencies(item.value);
         if (dep_check.result === true && typeof item.value.link !== 'undefined') {
            if (item.value.link.type === 'scoring') {
               if (typeof this.settings[item.key] !== 'undefined') {
                  this.settings[item.key].value = item.value.value;
               }
               result.push(item.value);
            }
         }
      });
      return result;
   }

   update_setting = (config) => {
      let setting = this.get_setting_by_key(config.key);
      if (setting !== null) {
         if (setting.data_type === 'int') {
            setting.value = parseInt(config.value);
         } else {
            setting.value = config.value;
         }
      }
   }

   update_settings_from_form = (formSettings = null) => {
      // console.log('update_settings_from_form -- formSettings: ', formSettings);
      if (formSettings !== null) {
         this.map(formSettings, (item) => {
            this.update_setting(item);
         });
      }
   }
}


export default CreateSport;