import React, { useState, useMemo, useEffect } from 'react';
import $ from 'jquery';
import Clock from '../../clock/clock';
import ScoreToolBlock from './toolblock/toolblock';
import TextMessage from './text-message/text-message.js';
import ScoringItem from '../scoring-item/scoring-item.js';
import DBHandler from '../../../javascript/index-db/db-handler.js';
import ServingIndicator from './serving-indicator/serving-indicator.js';
import { ConfirmModel, Sleep, Timestamp } from '../../../javascript/utils/utils.js';
import { endGame, speech, build_sport_function } from '../../../javascript/sport/sport-function.js';
import ConnectionIndicator from '../../connection-indicator/connection-indicator.js';
import { SPORT_BASE, NAV_CONTROL } from '../../../javascript/services/globalVars.js';
import style from './scoring-block-style.module.scss';

function ScoringBlock(Props) {
   const [gameTimer, setGameTimer] = useState(() => { });
   const [gameTimer_2, setGameTimer_2] = useState(() => { });
   const [hideGameTimer, setHideGameTimer] = useState(() => false);
   const [hideGameTimer_2, setHideGameTimer_2] = useState(() => true);
   const [debounceTime, setDebounceTime] = useState(() => Timestamp());
   const [scoreLeft, setScoreLeft] = useState(() => { });
   const [scoreRight, setScoreRight] = useState(() => { });
   const [colorTable, setColorTable] = useState(() => { });
   const [servingState, setServingState] = useState(() => { });
   const [scoreUpdate, setScoreUpdate] = useState(() => 0);
   const [btnList, setBtnList] = useState(() => []);

   let styleHeight = '';
   if (Props.cssHeight === '70') {
      styleHeight = style.hight70;
   }

   const confirm_game_won = () => {
      const scoring = SPORT_BASE.sport_object.scoring;
      if (SPORT_BASE.sport_object.game_state === 'running' && gameTimer !== false) {
         const wordType = scoring.scoring.match_won ? 'Match' : 'Game';
         const game_state = scoring.scoring.match_won ? 'match_complete' : 'game_complete';
         if (scoring.scoring.won && $('.swal2-container').length === 0) {
            ConfirmModel({
               title: `${wordType} completed?`,
               text: `Are you sure?`,
               confirmButtonText: 'Yes',
               cancelButtonText: 'Go Back!',
               allowOutsideClick: false,
               confirm_button_delay: 3,
               preConfirm: async (result) => {
                  return new Promise(async (resolve) => {
                     if (result) {
                        speech(wordType);
                        const dbHandler = DBHandler();
                        await dbHandler.update_app_state({ update: Timestamp(), game_state: game_state, });
                        await endGame();
                        stopClocks();
                     }
                     
                     await Sleep(250);
                     resolve(true);
                  });
               }

            }).then(async (result) => {
               if (!result.isConfirmed) {
                  SPORT_BASE.sport_object.scoring.scoring.undo();
                  if (typeof Props.callback === 'function') {
                     updateScore();
                     Props.callback();
                  }
               }

               // if (result.isConfirmed) {
               //    speech(wordType);
               //    const dbHandler = DBHandler();
               //    await dbHandler.update_app_state({ update: Timestamp(), game_state: game_state, });
               //    await endGame();
               //    stopClocks();
               //    // window.location.reload();
               // } else {
               //    // } else if (result.dismiss === 'cancel') {
               //    SPORT_BASE.sport_object.scoring.scoring.undo();
               //    if (typeof Props.callback === 'function') {
               //       updateScore();
               //       Props.callback();
               //    }
               // }
            });
         }
      }
   }
   

   const trigger_score = async (args = {}) => {
      try {
         const timePassed = Timestamp() - debounceTime;
         if (timePassed < 350) {
            return false;
         }
         const scoring = SPORT_BASE.sport_object.scoring;
         if (!scoring.scoring.won) {
            const side = args.pos === 'left' ? 1 : 2;
            const score_fun = build_sport_function({ type: 'score', value: side });
            const result = await score_fun();
            if (typeof Props.callback === 'function') { Props.callback(); }
         }
         // refresh('trigger_score - ok');
         setDebounceTime(Timestamp());
      } catch (error) {
         console.error('ERROR - Score-Block - trigger_score - root: ', error);
         refresh('trigger_score - error');
         setDebounceTime(Timestamp());
      }
   }
   
   const updateScore = () => {
      try {
         const scoring = SPORT_BASE.sport_object.scoring;
         setScoreLeft({ score: scoring.scoring.team_1_score.value });
         setScoreRight({ score: scoring.scoring.team_2_score.value });
      } catch (error) {
         console.error('ERROR - Score-Block - updateScore - root: ', error);
      }
   }

   const stopClocks = () => {
      setGameTimer(false);
      setGameTimer_2(false);
   }

   const updateState = async (args) => {

      if (typeof args.scoring.state !== 'undefined') {
         const game_clock = 'game_clock';
         const continuous_clock = 'continuous_clock';

         if (SPORT_BASE.check_continuous_clock()) {
            const game_timber_2 = SPORT_BASE.getClock(continuous_clock);
            setGameTimer_2(game_timber_2);
            setHideGameTimer(true);
            setHideGameTimer_2(false);
         } else {
            setGameTimer_2(false);
            setHideGameTimer(false);
            setHideGameTimer_2(true);
         }

         const clockIndex = SPORT_BASE.sport_object.clocks.findIndex(clock => clock.id === game_clock);
         if (clockIndex !== -1) {
            const game_timer = SPORT_BASE.sport_object.clocks[clockIndex];
            setGameTimer(game_timer);
         }
         setServingState(args.scoring.state.serving);
         setColorTable(args.scoring.state.match.players.color_table);
         const tools = await args.get_tool_block('scoring');
         setBtnList(tools);
         updateScore();
         confirm_game_won();
      } else {
         stopClocks();
      }
   }

   const refresh = (_type) => {
      setScoreUpdate(v => v + 1);
   }
   
   useMemo(() => { return updateState(SPORT_BASE.sport_object); }, [scoreUpdate])

   useEffect(() => {
      NAV_CONTROL.appendCallback('scoreBlock', () => { 
         refresh('useEffect'); 
         if (NAV_CONTROL.state.kill_clocks === true) {
            stopClocks();
            NAV_CONTROL.state.kill_clocks = false;
         }
      });

      return () => {
         // >>> Runs on unLoad (Unload runs first if component is already mounted)
      };
   }, []);
   
   return (
      <div className={`${style.scoreWrap} ${styleHeight}`}>
         <div className={style.scoreHead}>
            <Clock settings={gameTimer} hidden={hideGameTimer}  />
            <Clock settings={gameTimer_2} hidden={hideGameTimer_2} />
            <ConnectionIndicator/>
         </div>
         <div className={style.scoreBody}>
            <ScoringItem callback={trigger_score} settings={scoreLeft} pos="left" />
            <div className={style.scoreToolBar}>
               <ScoreToolBlock btnList={btnList} />
            </div>
            <ScoringItem callback={trigger_score} settings={scoreRight} pos="right" />
         </div>
         <div className={style.scoreFoot}>
            <ServingIndicator pos={1} servingState={servingState} update={scoreUpdate} colorTable={colorTable} />
            <TextMessage/>
            <ServingIndicator pos={2} servingState={servingState} update={scoreUpdate} colorTable={colorTable} />
         </div>
      </div>
   );
}

export default ScoringBlock;