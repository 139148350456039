import React, { useEffect, useState } from 'react';
import RemoteImage from '../remote_img/remote_img';
import { Timestamp } from '../../javascript/utils/utils';
import { CloseAllSwal, Sleep, genKeyRan } from '../../javascript/utils/utils';
import DBHandler from '../../javascript/index-db/db-handler';
import { SPORT_BASE, NAV_CONTROL } from '../../javascript/services/globalVars';
import { CAST_Update_Screen } from '../../javascript/cast/cast-control';

import style from './server-select-style.module.scss';

const dbHandler = DBHandler();

function ServerSelect(Props) {
   const [playerTemplate, setPlayerTemplate] = useState(() => []);
   const [image, setImage] = useState(() => '');

   useEffect(() => {
      const template = [];
      const add_player = (player, count) => {
         template.push({
            name: player.display_name,
            color: player.color,
            count: count,
         });
      }
      const { Match_Players } = SPORT_BASE.sport_object.get_player_settings();

      add_player(Match_Players.teams[0].players[0], 1);
      add_player(Match_Players.teams[1].players[0], 2);
      if (typeof Match_Players.teams[0].players[1] !== 'undefined') {
         add_player(Match_Players.teams[0].players[1], 3);
      }
      if (typeof Match_Players.teams[1].players[1] !== 'undefined') {
         add_player(Match_Players.teams[1].players[1], 4);
      }

      // >>> Get Club Image
      dbHandler.load_club().then((account_result) => {
         if (typeof account_result !== 'undefined' && account_result.club_logo !== '') {
            setImage(account_result.club_logo);
         }
      });
      setPlayerTemplate(template);
   }, []);

   const select = async (player) => {
      SPORT_BASE.sport_object.scoring.state.serving.updatePlayer(player);
      await dbHandler.update_app_state({ update: Timestamp(), game_state: 'running', });
      await SPORT_BASE.saveGameState();
      await SPORT_BASE.updateGameStateTimestamp();
      await Sleep(500);
      CAST_Update_Screen();
      CloseAllSwal();
   }



   useEffect(() => {
      NAV_CONTROL.appendCallback('ServerSelect', () => { 
         if (NAV_CONTROL.state.serving_player !== null) {
            // console.error('>>>>>> ServerSelect Player: ', NAV_CONTROL.state.serving_player);
            select(NAV_CONTROL.state.serving_player);
            NAV_CONTROL.state.serving_player = null;
         }
      });
      return () => {
         // >>> Runs on unLoad (Unload runs first if component is already mounted)
      };
   }, []); // >> [] -- Monitor


   return (
      <div className={style.wrap}>
         <div className={style.img_wrap} >
            <RemoteImage src={image} />
         </div>
         <div className={style.btn_wrap}>
            {playerTemplate.map(player => (
               <div key={genKeyRan('player-')} className={style.btn} style={{ background: player.color }} onClick={() => { select(player.count) }}> {player.name} </div>
            ))}
         </div>
      </div>
   );
} 

export default ServerSelect;