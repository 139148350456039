import React, { useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import style from './Input-counter-style.module.scss';

function InputCounter(Props) {
   // console.log('>>>InputTest ', Props)
   let value = Props.value;
   const type = Props.type;
   const placeholder = Props.placeholder;
   const name = Props.name;
   let input_ref = useRef(0);
 
   const count_plus = () => {
      let input = input_ref.current;
      input.value++;

      updateValue(input.value);



      // const lastValue = input_ref.current;
      // let input = input_ref.current;
      // input.value++;
      // const event = new Event("input", { bubbles: true });
      // const tracker = input._valueTracker;
      // if (tracker) { tracker.setValue(lastValue); }
      // input.dispatchEvent(event);
   }
   const count_minus = () => {
      let input = input_ref.current;
      input.value--;
      updateValue(input.value);



      // const lastValue = input_ref.current;
      // let input = input_ref.current;
      // input.value--;
      // const event = new Event("input", { bubbles: true });
      // const tracker = input._valueTracker;
      // if (tracker) { tracker.setValue(lastValue); }
      // input.dispatchEvent(event);
   }

   const updateValue = (val) => {
      const lastValue = input_ref.current;
      let input = input_ref.current;
      input.value = val;
      const event = new Event("input", { bubbles: true });
      const tracker = input._valueTracker;
      if (tracker) { tracker.setValue(lastValue); }
      input.dispatchEvent(event);
   }

   useEffect(() => {
      // console.log('Input counter  --- useEffect');
      // console.log('value: ', value);
      updateValue(value);
   }, []);


   useEffect(() => {
      // console.log('Input counter  --- useEffect');
      // console.log('value: ', value);
      updateValue(value);
   }, [value]);





   
   return (<div className={style.wrap}>
      <div className={style.counterBtn} onClick={count_minus}><FontAwesomeIcon icon={faMinus} /></div>
      <input ref={input_ref} type={type} defaultValue={value} name={name} placeholder={placeholder} />
      <div className={style.counterBtn} onClick={count_plus}><FontAwesomeIcon icon={faPlus} /></div>
   </div>);
}

export default InputCounter;