import React, { useState, useEffect, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWrench, faBullhorn, faListOl } from '@fortawesome/free-solid-svg-icons';
import PageWrap from '../../page-wraper/page-wrap';
import ScoringFoot from '../foot/scoring-foot';
import ScoringHead from '../head/scoring-head';
import PaidStatus from '../../paid_sts/paid-status';
import SideMenu from '../../side-menu/side-menu';
import MainMenu from '../../main-menu/main-menu';
import ScoringBlock from '../score-block/score-block';
import SideMenuBtn from '../side-menu-btn/side-menu-btn';
import WarmUpBlock from '../warm-up-block/warm-up-block';
import EndGameBlock from '../end-game-block/end-game-block';
import ScoreToolbar from '../../score-toolbar/score-toolbar';
import SettingsMenu from '../../settings-menu/settings-menu';
import ServerSelect from '../../server-select/server-select';
import { ConfirmModel } from '../../../javascript/utils/utils';
import DBHandler from '../../../javascript/index-db/db-handler';
import { AppContextProvider } from '../../app-context/app-context';
import { CAST_Update_Screen } from '../../../javascript/cast/cast-control';
import { SPORT_BASE, NAV_CONTROL, GLOBAL_VARS, GLOBAL_CSS } from '../../../javascript/services/globalVars';
import styles from './scoring-core-style.module.scss';

function ScoringCore(Props) {
   const sport = "Squash";
   const [warmupTimer, setWarmupTimer] = useState(() => { });
   const [endGameTimer, setEndGameTimer] = useState(() => { });
   const [navUpdate, setNavUpdate] = useState(() => 0);
   const [leftMenuState, setLeftMenuState] = useState(0);
   const [rightMenuState, setRightMenuState] = useState(0);
   const [bottomMenuState, setBottomMenuState] = useState(0);
   const [warmupVisibility, setWarmupVisibility] = useState(0);
   const [endGameVisibility, setEndGameVisibility] = useState(0);
   const [scoringFootHeight, setScoringFootHeight] = useState(0);
   const [scoringBlockHeight, setScoringBlockHeight] = useState(0);
   const [leftMenuClose, setLeftMenuClose] = useState(true);
   const [onUpdate, setOnUpdate] = useState(() => 0);

   const open_left_menu = () => {
      setLeftMenuState(1);
      NAV_CONTROL.state.main_menu_open = true;
   }
   const close_left_menu = () => {
      setLeftMenuState(0);
      NAV_CONTROL.state.main_menu_open = false;
   }
   const open_right_menu = () => {
      NAV_CONTROL.state.right_menu_open = true;
      setRightMenuState(1);
      // console.log('Open Right Menu')
   }
   const close_right_menu = () => {
      NAV_CONTROL.state.right_menu_open = false;
      setRightMenuState(0);
   }
   const open_bottom_menu = () => {
      setBottomMenuState(1);
      setScoringFootHeight('20');
      setScoringBlockHeight('70');
   }
   const close_bottom_menu = () => {
      setBottomMenuState(0);
      setScoringFootHeight(0);
      setScoringBlockHeight(0);
   }

   function roughSizeOfObject(object) { /// DEBUG Code
      const seen = new WeakSet();
      function sizeOf(obj) {
         if (obj === null || obj === undefined) return 0;
         if (typeof obj === 'boolean') return 4;
         if (typeof obj === 'number') return 8;
         if (typeof obj === 'string') return obj.length * 2;
         if (typeof obj === 'object') {
            if (seen.has(obj)) return 0;
            seen.add(obj);

            return Object.keys(obj).reduce((total, key) => {
               return total + sizeOf(obj[key]);
            }, 0);
         }
         return 0;
      }

      return sizeOf(object);
   }

   function bytesToMB(bytes) { /// DEBUG Code
      return (bytes / (1024 * 1024)).toFixed(2); // Convert and round to 2 decimal places
   }

   // function trackObjectSizeChanges() {
   //    let previousSizeMap = new Map();
   //    function calculateSize(obj, path = '', seen = new WeakSet(), depth = 0) {
   //       if (obj === null || obj === undefined) return 0;
   //       if (typeof obj === 'object') {
   //          if (seen.has(obj)) return 0; // Avoid circular references
   //          seen.add(obj);
   //       }
   //       let totalSize = 0;
   //       const isTrackedLevel = depth <= 1; // Track up to second level
   //       if (typeof obj === 'boolean') totalSize = 4;
   //       else if (typeof obj === 'number') totalSize = 8;
   //       else if (typeof obj === 'string') totalSize = obj.length * 2;
   //       else if (Array.isArray(obj)) {
   //          totalSize = obj.reduce((sum, item, index) => sum + calculateSize(item, `${path}[${index}]`, seen, depth + 1), 0);
   //       } else if (typeof obj === 'object') {
   //          totalSize = Object.entries(obj).reduce((sum, [key, value]) => {
   //             const keySize = key.length * 2; // Key storage
   //             const valueSize = calculateSize(value, `${path}.${key}`, seen, depth + 1);
   //             const entrySize = keySize + valueSize;
   //             if (isTrackedLevel) previousSizeMap.set(`${path}.${key}`, entrySize); // Store top 2 levels
   //             return sum + entrySize;
   //          }, 0);
   //       }
   //       if (path === '') previousSizeMap.set('root', totalSize); // Track total size
   //       return totalSize;
   //    }
   //    return function (obj) {
   //       const totalSize = calculateSize(obj);
   //       console.log(`\n🔍 Total Estimated Object Size: ${totalSize} bytes (${(totalSize / (1024 * 1024)).toFixed(4)} MB)`);
   //       if (previousSizeMap.size > 1) {
   //          console.log("\n📊 Changes in Surface-Level & Second-Level Object Size:");
   //          let changes = [];
   //          for (const [key, newSize] of previousSizeMap.entries()) {
   //             if (key === 'root') continue; // Skip total size
   //             const oldSize = previousSizeMap.get(key) || 0;
   //             if (newSize !== oldSize) {
   //                changes.push({ key, oldSize, newSize, diff: newSize - oldSize });
   //             }
   //          }
   //          changes.sort((a, b) => Math.abs(b.diff) - Math.abs(a.diff)); // Sort by biggest change
   //          changes.forEach(change => {
   //             console.log(`  ${change.key}: ${change.oldSize} → ${change.newSize} bytes (Δ${change.diff})`);
   //          });
   //       }
   //       return totalSize;
   //    };
   // }

   // function measureTopLevelFieldSizes(obj) {
   //    function getSize(value) {
   //       if (value === null || value === undefined) return 0;
   //       if (typeof value === "boolean") return 4;
   //       if (typeof value === "number") return 8;
   //       if (typeof value === "string") return value.length * 2;
   //       if (typeof value === "function") return 50; // Approximate function size
   //       if (Array.isArray(value)) return value.length * 8; // Approximate array size
   //       if (typeof value === "object") {
   //          try {
   //             return JSON.stringify(value).length * 2; // Rough object size
   //          } catch (e) {
   //             return 0; // Handle circular references
   //          }
   //       }
   //       return 0;
   //    }
   //    console.log("\n📊 Surface-Level Field Sizes:");
   //    let totalSize = 0;
   //    Object.entries(obj).forEach(([key, value]) => {
   //       const size = getSize(value);
   //       totalSize += size;
   //       console.log(`  ${key}: ${size} bytes`);
   //    });
   //    console.log(`\n🔍 Total Estimated Surface-Level Size: ${totalSize} bytes (${(totalSize / 1024).toFixed(4)} KB)`);
   // }



   const game_state_control = async () => {
      const dbHandler = DBHandler();
      var previous_app_state = null;
      var previous_game_state = null;
      var previous_update_timestamp = null;


      // console.warn('>>> loadGameState <<<');
      // await SPORT_BASE.loadGameState(); 

      // const trackSize = trackObjectSizeChanges();



      dbHandler.init().then(() => {
         dbHandler.watch_app_state(async (appState) => {
            const app_state = appState.app_state;
            const game_state = appState.game_state;
            const update_timestamp = appState.update;
            if (previous_app_state !== app_state || previous_game_state !== game_state || previous_update_timestamp !== update_timestamp) {
               previous_app_state = app_state;
               previous_game_state = game_state;
               previous_update_timestamp = update_timestamp;
               SPORT_BASE.app_state = app_state;
               SPORT_BASE.sport_object.game_state = game_state;

               setOnUpdate(i => i + 1);

               if (game_state === null) {
                  setLeftMenuClose(false);
                  open_left_menu();
               } else {
                  setLeftMenuClose(true);
               }

               if (GLOBAL_VARS.cast_running) {
                  CAST_Update_Screen();
               }

               // >>> Load Game State
               await SPORT_BASE.loadGameState();
               // console.warn('>>> loadGameState <<<');
               // console.warn('>>> SPORT_BASE DEBUG:: ', SPORT_BASE);
               console.log(`SPORT_BASE - Size: ${bytesToMB(roughSizeOfObject(SPORT_BASE))} MB`);
               // console.log(`SPORT_BASE - scoring - Size: ${bytesToMB(roughSizeOfObject(SPORT_BASE.sport_object.scoring.scoring))} MB`);
               // trackSize(SPORT_BASE); /// DEBUG Code
               // measureTopLevelFieldSizes(SPORT_BASE.sport_object.scoring.scoring);

               if (app_state === 'init') {
                  open_left_menu();
               } else if (app_state === 'match') {
                  if (game_state === 'warmup') {
                     close_left_menu();
                     close_right_menu();
                     setWarmupVisibility(1);
                     setEndGameVisibility(0);
                     const warmupClock = 'warmup_timer';
                     const clockIndex = SPORT_BASE.sport_object.clocks.findIndex(clock => clock.id === warmupClock);
                     if (clockIndex !== -1) {
                        const warmup_timer = SPORT_BASE.sport_object.clocks[clockIndex];
                        setWarmupTimer(warmup_timer);
                     }
                  } else if (game_state === 'server_select') {
                     setWarmupTimer(false);
                     setEndGameTimer(false);
                     setWarmupVisibility(0);
                     setEndGameVisibility(0);
                     setOnUpdate(i => i + 1);
                     ConfirmModel({
                        icon: '',
                        title: `Who will start Serving?`,
                        html: (<ServerSelect />),
                        showConfirmButton: false,
                        showCancelButton: false,
                        allowOutsideClick: false,
                        showCancelButton: false,
                     })
                  } else if (game_state === 'running') {
                     setWarmupTimer(false);
                     setEndGameTimer(false);
                     setWarmupVisibility(0);
                     setEndGameVisibility(0);
                     setOnUpdate(i => i + 1);
                  } else if (game_state === 'game_complete' || game_state === 'match_complete') {
                     if (game_state === 'game_complete') {
                        let clockName;
                        if (typeof SPORT_BASE.sport_object.between_games.setup.clock !== 'undefined') {
                           clockName = SPORT_BASE.sport_object.between_games.setup.clock.id
                        }
                        const endGame_timer = SPORT_BASE.getClock(clockName);
                        setEndGameTimer(endGame_timer);
                     }
                     setEndGameVisibility(1);
                  }
               }
            }
         });
      })


   }



   useEffect(() => {
      game_state_control();
      // const dbHandler = DBHandler();
      // var previous_app_state = null;
      // var previous_game_state = null;
      // var previous_update_timestamp = null;
      // dbHandler.init().then(() => {
      //    dbHandler.watch_app_state(async (appState) => {
      //       const app_state = appState.app_state;
      //       const game_state = appState.game_state;
      //       const update_timestamp = appState.update;
      //       console.error('XX');
      //       if (previous_app_state !== app_state || previous_game_state !== game_state || previous_update_timestamp !== update_timestamp) {
      //          previous_app_state = app_state;
      //          previous_game_state = game_state;
      //          previous_update_timestamp = update_timestamp;
      //          SPORT_BASE.app_state = app_state;
      //          SPORT_BASE.sport_object.game_state = game_state;
      //          setOnUpdate(i => i + 1);
      //          if (game_state === null) {
      //             setLeftMenuClose(false);
      //             open_left_menu();
      //          } else {
      //             setLeftMenuClose(true);
      //          }
      //          if (GLOBAL_VARS.cast_running) {
      //             CAST_Update_Screen();
      //          }
      //          // >>> Load Game State
      //          // await SPORT_BASE.loadGameState();
      //          console.warn('>>> loadGameState <<<');
      //          if (app_state === 'init') {
      //             open_left_menu();
      //          } else if (app_state === 'match') {
      //             if (game_state === 'warmup') {
      //                close_left_menu();
      //                close_right_menu();
      //                setWarmupVisibility(1);
      //                setEndGameVisibility(0);
      //                const warmupClock = 'warmup_timer';
      //                const clockIndex = SPORT_BASE.sport_object.clocks.findIndex(clock => clock.id === warmupClock);
      //                if (clockIndex !== -1) {
      //                   const warmup_timer = SPORT_BASE.sport_object.clocks[clockIndex];
      //                   setWarmupTimer(warmup_timer);
      //                }
      //             } else if (game_state === 'server_select') {
      //                setWarmupTimer(false);
      //                setEndGameTimer(false);
      //                setWarmupVisibility(0);
      //                setEndGameVisibility(0);
      //                setOnUpdate(i => i + 1);
      //                ConfirmModel({
      //                   icon: '',
      //                   title: `Who will start Serving?`,
      //                   html: (<ServerSelect/>),
      //                   showConfirmButton: false,
      //                   showCancelButton: false,
      //                   allowOutsideClick: false,
      //                   showCancelButton: false,
      //                })
      //             } else if (game_state === 'running') {
      //                setWarmupTimer(false);
      //                setEndGameTimer(false);
      //                setWarmupVisibility(0);
      //                setEndGameVisibility(0);
      //                setOnUpdate(i => i + 1);
      //             } else if (game_state === 'game_complete' || game_state === 'match_complete') {
      //                if (game_state === 'game_complete') {
      //                   let clockName;
      //                   if (typeof SPORT_BASE.sport_object.between_games.setup.clock !== 'undefined') {
      //                      clockName = SPORT_BASE.sport_object.between_games.setup.clock.id
      //                   }
      //                   const endGame_timer = SPORT_BASE.getClock(clockName);
      //                   setEndGameTimer(endGame_timer);
      //                }
      //                setEndGameVisibility(1);
      //             }
      //          }
      //       }
      //    });
      // })
   }, []); // >> Runs on Mount only

   const score_callback = () => {
      return new Promise(async (resolve) => {
         GLOBAL_VARS.matched_changed = Date.now();
         await SPORT_BASE.saveGameState('score_callback');
         await SPORT_BASE.updateGameStateTimestamp();
         resolve(true);
      })
   }
   useEffect(() => {
      if (NAV_CONTROL.state.main_menu_open) {
         open_left_menu();
      } else {
         close_left_menu();
      }
      if (NAV_CONTROL.state.right_menu_open) {
         open_right_menu();
      } else {
         close_right_menu();
      }
   }, [navUpdate]);

   useEffect(() => {
      NAV_CONTROL.appendCallback('scoringCore', () => { setNavUpdate(v => v + 1); });
      return () => {
         // >>> Runs on unLoad (Unload runs first if component is already mounted)
      };
   }, []); // >> [] -- Monitor

   const pre_open_left_menu = () => {
      if (SPORT_BASE.sport_object.game_state === 'warmup' || SPORT_BASE.sport_object.game_state === 'running' || SPORT_BASE.sport_object.game_state === 'game_complete') {
         NAV_CONTROL.openMainMenuEdit();
      }
      open_left_menu();
   }

   return (
      <PageWrap>
         <AppContextProvider>
            <div className={styles.core} style={GLOBAL_CSS.background} >
               <ScoringHead update={onUpdate}></ScoringHead>
               <ScoringBlock cssHeight={scoringBlockHeight} callback={score_callback} ></ScoringBlock>
               <ScoringFoot cssHeight={scoringFootHeight} >
                  <ScoreToolbar state={bottomMenuState} commonTriggers={Props.commonTriggers} callback={close_bottom_menu}></ScoreToolbar>
               </ScoringFoot>
               <WarmUpBlock visibility={warmupVisibility} warmupTimer={warmupTimer} ></WarmUpBlock>
               <EndGameBlock visibility={endGameVisibility} timer={endGameTimer}  ></EndGameBlock>
               <SideMenuBtn side="left" onClick={pre_open_left_menu}> <FontAwesomeIcon icon={faWrench} /> </SideMenuBtn>
               <SideMenuBtn side="right" onClick={open_right_menu}> <FontAwesomeIcon icon={faListOl} /> </SideMenuBtn>
               <SideMenuBtn side="bottom" hide={bottomMenuState} onClick={open_bottom_menu}> <FontAwesomeIcon icon={faBullhorn} /> </SideMenuBtn>
               <SideMenu sport={sport} allowClose={leftMenuClose} state={leftMenuState} callback={close_left_menu} specialMenu={MainMenu}></SideMenu>
               <SideMenu sport={sport} state={rightMenuState} callback={close_right_menu} specialMenu={SettingsMenu} pos="right"></SideMenu>
               <PaidStatus state={bottomMenuState === 1 ? 0 : 1} />
            </div>
         </AppContextProvider>
      </PageWrap>
   );
}

export default ScoringCore;