import { Sleep, genRanID } from "../utils/utils";
import { GLOBAL_VARS } from "../services/globalVars";
import { CAST_Update_Screen, CAST_Rest_Clock } from "./cast-control";


var applicationId = process.env.REACT_APP_CAST_RECEIVER_ID; // "E3D6BA50"; //61F63EDA
var namespace = "urn:x-cast:ScoringCasting"
var Context = null;
var castSession = null;
var message_buffer = [];

// console.log('applicationId: ', applicationId);

/**
 * Call initialization for Cast
 */
window["__onGCastApiAvailable"] = function (loaded, errorInfo) {
   if (loaded) {
      console.debug('%c Casting Starting up', 'color: #00adff');
      init_cast();
   }
}

const isFrameworkLoaded = () => {
   if (typeof window.cast === 'undefined') {
      return false;
   } else {
      return true;
   }
}
const wait_for_framework = () => {
   return new Promise(async (resolve) => {
      let result = await Sleep(100, isFrameworkLoaded, 10);
      resolve(result);
   })
}


// const onError = (e) => { console.error('Cast on Error: ', e) }
// const onSuccess = (e) => { console.error('Cast on Success: ', e) }


const init_cast = () => {
   console.debug('>>> init_cast <<<');
   // console.debug('Cast: ', window.cast);
   // console.debug('Chrome cast: ', window.chrome.cast)

   wait_for_framework().then(() => {
      console.debug('Load Cast: ', window.cast);
      console.debug('Load Chrome cast: ', window.chrome.cast)
      window.cast.framework.setLoggerLevel(window.cast.framework.LoggerLevel.DEBUG);
      Context = window.cast.framework.CastContext.getInstance();
      Context.setOptions({
         receiverApplicationId: applicationId,
         autoJoinPolicy: window.chrome.cast.AutoJoinPolicy.ORIGIN_SCOPED,
         resumeSavedSession: true
      });

      Context.requestSession();
      Context.addEventListener(window.cast.framework.CastContextEventType.SESSION_STATE_CHANGED, (e) => {
         castSession = window.cast.framework.CastContext.getInstance().getCurrentSession();

         // console.debug('SESSION_STATE_CHANGED: ', e)
         // console.error('cast session: ', castSession);
         if (e.sessionState === "SESSION_START_FAILED") {
            castSession.endSession();
            castSession = null;
            CAST_Rest_Clock();
         } else if (e.sessionState === "SESSION_STARTED") {
         } else if (e.sessionState === "SESSION_RESUMED") {
            console.warn('Cast RESUMED');
            GLOBAL_VARS.cast_running = true;
         } else if (e.sessionState === "SESSION_ENDED") {
            castSession.endSession();
            castSession = null;
            CAST_Rest_Clock();
         }
      });


      Context.addEventListener(window.cast.framework.CastContextEventType.CAST_STATE_CHANGED, (e) => {
         // console.warn('CAST_STATE_CHANGED: ', e)
         if (e.castState === 'CONNECTED') {
            GLOBAL_VARS.cast_running = true;

            castSession.addMessageListener(namespace, (namespaceID, msg) => {
               // console.log('Cast Message: ', namespaceID, msg);
               // console.log('msg: ', msg);
               message_buffer = msg;

               setTimeout(function () {
                  // sendMessage({ data: 'Message Received' });
               }, 5000);
            });

            sendMessage({ data: 'Starting Connection' });
            setTimeout(function () {
               CAST_Update_Screen();
               keep_alive();
               // auto_loop(castSession);
            }, 500);
         } else {
            // console.warn('DEBUG ---------- e.castState: ', e.castState);
            // GLOBAL_VARS.cast_running = false;
            // CAST_Rest_Clock();
         }
      });
      console.debug('......CAST FRAMEWORK UP......');
   });
}



const keep_alive = async () => {
   try {
      await sendMessage({
         data: 'keep_alive',
         control: [{
            message_id: genRanID(),
            control: 'keep_alive',
            data: '',
         }]
      }, true);
      await Sleep(30_000);
      keep_alive();
   } catch (error) {
     console.error('ERROR - CAST Service - keep_alive: ', error);
   }
}


// const auto_loop = (session) => {
//    let url = "https://ia802508.us.archive.org/5/items/testmp3testfile/mpthreetest.mp3"
//    var mediaInfo = new window.chrome.cast.media.MediaInfo(url, 'audio/aac');
//    mediaInfo.metadata = new window.chrome.cast.media.MusicTrackMediaMetadata()
//    mediaInfo.metadata.title = 'Hidden Tract';
//    // mediaInfo.metadata.subtitle = 'This is the name of the artist';
//    // mediaInfo.metadata.albumName = 'This is the name of the album'
//    // mediaInfo.metadata.artistName = 'This is the name of the artist'
//    // mediaInfo.metadata.songName = 'This is the name of the song'
//    // let im = window.chrome.cast.Image('http://m1.behance.net/rendition/modules/575407/disp/822271229466847.png')
//    // mediaInfo.metadata.images = new Array(im)
//    var request = new window.chrome.cast.media.LoadRequest(mediaInfo);
//    console.log('HI ', request);
//    console.log('castSession ', session);
//    // castSession.loadMedia(request,Context.onMediaDiscovered.bind(this, 'loadMedia'), Context.onMediaError())
//    session.loadMedia(request).then((e) => { 
//       console.log('After loadMedia', e) 
//       Sleep(12000).then(() => {
//          console.log('Running AGAIN -------------') ;
//          auto_loop(session);
//       })
//    });
// }


const await_message_reply = (control, id) => {
   return new Promise(async (resolve) => {
      // console.log('>>> await_message_reply <<<');
      // console.log('control: ', control);
      // console.log('id: ', id);

      const max = 100;
      for (let a = 0; a < max; a++) {
         // console.log('message_buffer: ', message_buffer);
         if (typeof message_buffer === 'string') {
            try {
               const msg = JSON.parse(message_buffer)
               // console.log('X: '+msg[0].control+' - '+control);
               // console.log('Y: '+msg[0].id+' - '+id);
               if (msg[0].control === control && msg[0].id === id) {
                  // console.warn('We have a hit - ', control);
                  resolve(msg[0].data);
                  return true;
               }
            } catch (error) {
            }
         }
         await Sleep(200);
      }
      resolve(false);
   });
}


/**
 * send a message to the receiver using the custom namespace
 * receiver CastMessageBus message handler will be invoked
 * @param {string} message A message string
 */
const sendMessage = (message, await_result = false) => {
   return new Promise((resolve) => {
      try {
         // console.debug('sendMessage: ', message);
         // var data = message;
         // console.log('castSession: ', castSession);
         if (castSession != null) {
            // console.debug('namespace: ', namespace)
            castSession.sendMessage(namespace, message).then(msg => {
               if (await_result) {
                  // console.log('sendMessage msg: ', message)
                  await_message_reply(message.control[0].control, message.control[0].message_id).then(result => {
                     console.debug('await_message_reply -- result: ', result);
                     resolve(result);
                  });
               } else {
                  resolve(true);
               }
            }).catch(err => {
               console.debug('sendMessage ERR: ', err)
               resolve(false);
            });
         }
      } catch (error) {
         console.error('ERROR sendMessage');
         resolve(false);
      }
   });
}



function onLoad() { init_cast(); }
if (window.attachEvent) { window.attachEvent('onload', onLoad); }



export { sendMessage }