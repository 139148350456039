import { SPORT_BASE } from "../services/globalVars";
import { NAV_CONTROL } from "../services/globalVars";
import { ConfirmModel } from "./utils";
import { endMatchPrompt } from "../sport/sport-function";
import { stopAllClocks } from "../sport/sport-function";

const Handle_Game_Change = async (matchSettings, type = 'new', match_code = null, push_match = null, match_players = null) => {
   return new Promise(async (resolve) => {
      // console.log('>>> Handle_Game_Change <<<');
      // console.log('type: ', type);
      // console.log('matchSettings: ', matchSettings);
      // console.log('game_state: ', SPORT_BASE.sport_object.game_state);

      if (type === 'mod') {
         if (SPORT_BASE.sport_object.game_state === 'running' || SPORT_BASE.sport_object.game_state === 'warmup') {
            if (SPORT_BASE.sport_object.scoring.state.match.games.length === 1) {
               // console.log('Check: ', SPORT_BASE.sport_object.scoring.state.match);
               // console.log('Mod Match....', matchSettings);
               SPORT_BASE.saveGameState().then((result) => {
                  SPORT_BASE.modMatch(matchSettings).then((result) => {
                     if (result) {
                        ConfirmModel({
                           title: `Update Successful`,
                           text: `Match has been Modified`,
                           confirmButtonText: 'Ok',
                           showCancelButton: false,
                           allowOutsideClick: true,
                        }).then((result) => {
                           NAV_CONTROL.closeMainMenu();
                        });
                     } else {
                        console.error('Error updating Match');
                        ConfirmModel({
                           icon: 'error',
                           title: `Unknown Error trying to modify match`,
                           text: `Please try again`,
                           confirmButtonText: 'Ok',
                           showCancelButton: false,
                           allowOutsideClick: true,
                        });
                     }
                  });
               });
            } else {
               ConfirmModel({
                  icon: 'warning',
                  title: ``,
                  text: `Unable to modify match after the first game is complete`,
                  confirmButtonText: 'Ok',
                  showCancelButton: false,
                  allowOutsideClick: true,
               });
            }
         } else {
            ConfirmModel({
               icon: 'warning',
               title: ``,
               text: `Unable to modify match while game isn't running`,
               confirmButtonText: 'Ok',
               showCancelButton: false,
               allowOutsideClick: true,
            });
         }
         resolve(true);
      } else {
         console.log('match_state: ', SPORT_BASE.sport_object.scoring.state.match.match_state);
         if (SPORT_BASE.sport_object.scoring.state.match.match_state !== 'match_won' && SPORT_BASE.sport_object.scoring.state.match.match_state !== '') {
            const confirmResult = await ConfirmModel({
               // icon: 'warning',
               title: `Match in Progress`,
               text: `A match is still in progress, Please Select:`,
               confirmButtonText: 'End match and assign a winner',
               cancelButtonText: 'Continue previous match',
               denyButtonText: 'Clear existing match & start new match',
               cancelButtonColor: 'var(--green)',
               denyButtonColor: 'var(--green)',
               showDenyButton: true,
               showCancelButton: true,
               allowOutsideClick: false,
            });
            if (confirmResult.isConfirmed) { // End match and assign a winner
               const result = await endMatchPrompt();
               console.log('End match prompt closed without a winner');
               if (result === false) {
                  resolve(false);
                  return '';
               } 
            } else if (confirmResult.isDenied) { // Clear existing match & start new match
               NAV_CONTROL.closeMainMenu();   
               SPORT_BASE.sport_object.scoring.state.match.match_state = 'match_cleared';
               await SPORT_BASE.saveGameState();
            } else if (confirmResult.isDismissed && confirmResult.dismiss === 'cancel') { // Continue previous match
               NAV_CONTROL.closeMainMenu();
               resolve(false);
               return '';
            }
         } else {
            NAV_CONTROL.closeMainMenu();
         }
         // console.log('>>>>-- matchSettings: ', matchSettings);
            stopAllClocks().then(() => {
            SPORT_BASE.initMatch(matchSettings, match_code, push_match, match_players).then((result) => {
               // console.log('Init result: ', result);
               SPORT_BASE.saveGameState('handleSubmit').then(() => {
                  resolve(result);
               });
            });
         });
      }

   });
}




export {
   Handle_Game_Change
}