import DBHandler from "../index-db/db-handler";
import { Clock_Settings, ActionLink } from "./class/coreSport";
import { ConfirmModel, Timestamp } from "../utils/utils";
import { SPORT_BASE, NAV_CONTROL, OVERLAY, SETTINGS, GLOBAL_VARS } from "../services/globalVars";
import WebSpeech from "../webSpeechAPI/webSpeech";
import LoginForm from "../../components/login-form/login-form";
import OverlaySlideMenu from "../../components/overlay-popup/overlay-slide-menu";
import ScoreTotals from "../../components/score-totals/score-totals";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import style from '../../style/share-style.module.scss';
import $ from 'jquery';
import { CAST_Rest_Clock, CAST_Update_Screen } from "../cast/cast-control";
import Sync from "../services/sync";
import Auth from "../services/auth";
import { Wait_For_Auth, Logout, Sleep, DenySwalBox, ConfirmSwalBox } from "../utils/utils";


const speechObj = WebSpeech();
const dbHandler = DBHandler();


const build_sport_function = (args) => {
   // console.log('args: ', args)
   try {
      if (typeof args.length === 'undefined') {
         return convert_sport_function(args);
      } else {
         const funList = args.map(f => convert_sport_function(f));
         return () => {
            return new Promise(async (resolve) => {
               for (let a = 0; a < funList.length; a++) {
                  await funList[a]();
               }
               resolve(true);
            })
         }
      }
   } catch (error) {
      console.error('ERROR - build_sport_function: ', error)
      return false;
   }
}

const convert_sport_function = (args) => {
   // console.log('>>convert_sport_function :', args)
   if (args.type === 'null') { return () => { return new Promise((resolve) => { resolve(true) }) } }
   switch (args.type) {
      case 'score':
         return () => { return new Promise((resolve) => { resolve(trigger_score(args.value)) }) }
      case 'select_server':
         return () => { return new Promise((resolve) => { resolve(NAV_CONTROL.setServingPlayer(args.value)) }) }
      case 'startMatch':
         return startMatch
      case 'setTimer':
         return () => { return new Promise((resolve) => { resolve(setTimer(args)) }) }
      case 'sideSwap':
         return sideSwap
      case 'screenFun':
         return () => { return new Promise((resolve) => { resolve(screenFun(args)) }) }
      case 'speechFun':
         return () => { return new Promise((resolve) => { resolve(speechFun(args)) }) }
      case 'completeMatch':
         return completeMatch
      case 'speech':
         return () => { speech(args.value, false); }
      case 'log':
         return async () => {
            const side = SPORT_BASE.sport_object.scoring.state.serving.side;
            switch (args.value) {
               case '(Stroke)':
                  const stroke_action = side === 1 ? 'p1stroke' : 'p2stroke';
                  SPORT_BASE.sport_object.scoring.state.match.active_game.add_log({
                     action: stroke_action,
                     content: 'stroke',
                     server_side: side,
                     score_side: side,
                  });
                  await SPORT_BASE.saveGameState('score_callback');
                  await SPORT_BASE.updateGameStateTimestamp();
                  break;
               case '(Let)':
                  const Let_action = side === 1 ? 'p1let' : 'p2let';
                  SPORT_BASE.sport_object.scoring.state.match.active_game.add_log({
                     action: Let_action,
                     content: 'let',
                     server_side: side,
                     score_side: side,
                  });
                  await SPORT_BASE.saveGameState('score_callback');
                  await SPORT_BASE.updateGameStateTimestamp();
                  break;
               case '(No Let)':
                  const no_Let_action = side === 1 ? 'p1nolet' : 'p2nolet';
                  SPORT_BASE.sport_object.scoring.state.match.active_game.add_log({
                     action: no_Let_action,
                     content: 'no let',
                     server_side: side,
                     score_side: side,
                  });
                  await SPORT_BASE.saveGameState('score_callback');
                  await SPORT_BASE.updateGameStateTimestamp();
                  break;
               default:
                  break;
            }
         }
      case 'megaPhone_test':
         return megaPhoneTest
      case 'login':
         return open_login
      case 'logout':
         return logout
      case 'mute':
         return muteSound
      case 'sounds_test':
         return SoundsTest
      case 'Repeat':
         return repeatLastMessage
      case 'end_match':
         return endMatchPrompt
      case 'end_match_confirm':
         return endMatchConfirm
      case 'score_undo':
         return score_undo
      case 'save_serving_defaults':
         return save_serving_defaults
      case 'update_game_state_timestamp':
         return update_game_state_timestamp
      case 'change_device_reg':
         return change_device_reg
      case 'change_sport_reg':
         return change_sport_reg
      case 'clear_database':
         return clear_database
      case 'clean_last_message':
         return clean_last_message
      case 'add_10_points_continuous':
         return add_10_points_continuous
      case 'reverse_score_continuous':
         return reverse_score_continuous
      case 'free_app_status':
         return free_app_status
      case 'nav':
         return () => { return new Promise((resolve) => { resolve(nav_control(args)) }) }
      case 'sub_menu':
         return () => { return new Promise((resolve) => { resolve(sub_menu(args)) }) }
      case 'cast_btn':
         return () => { return new Promise((resolve) => { resolve() }) }
      case 'confirm_box_confirm':
         return () => { return new Promise((resolve) => { resolve(ConfirmSwalBox()) }) }
      case 'confirm_box_deny':
         return () => { return new Promise((resolve) => { resolve(DenySwalBox()) }) }
      default:
         console.error(`build_sport_function Error: ${args.type} Not Implemented`);
         console.log('args: ', args);
         return () => { return new Promise((resolve) => { resolve(console.error('Not Implemented')) }) }
   }
}

const clear_database = () => {
   return new Promise(async (resolve) => {
      const result = await ConfirmModel({
         title: `Clear Database`,
         text: `Clearing the database will log you out and remove all match data. Are you sure you want to proceed?`,
         confirmButtonText: 'Yes',
         showCancelButton: true,
         allowOutsideClick: true,
      });
      if (result.isConfirmed) {
         Logout();
      }
   });
}

const trigger_score = (side) => {
   return new Promise(async (resolve) => {
      const result = await SPORT_BASE.sport_object.scoring.scoring.score({ side });
      SPORT_BASE.saveGameState('trigger_score');
      NAV_CONTROL.refreshScore('trigger_score()');
      dbHandler.update_app_state({ update: Timestamp() });
      resolve(result);
   });
}

const change_sport_reg = () => {
   return new Promise(async (resolve) => {
      if (SPORT_BASE.sport_object.scoring.state.match.match_state !== 'match_won') {
         await endMatchPrompt();
      }

      const authObj = Auth();
      await Wait_For_Auth(authObj);
      authObj.init().then(async (authResult) => {
         const syncObj = new Sync(authObj, {});
         // >>> What for sme_con         
         while (!syncObj.sme_con.ready) {
            if (syncObj.sme_con.client === false) {
               resolve(false);
               return
            }
            await Sleep(50);
         }
         await syncObj.select_sport();
         window.location.reload();
      });
   });
}

const change_device_reg = () => {
   return new Promise(async (resolve) => {
      const authObj = Auth();
      await Wait_For_Auth(authObj);
      authObj.init().then(async (authResult) => {
         const syncObj = new Sync(authObj, {});
         // >>> What for sme_con         
         while (!syncObj.sme_con.ready) {
            if (syncObj.sme_con.client === false) {
               resolve(false);
               return
            }
            await Sleep(50);
         }
         await syncObj.register_device();
         window.location.reload();
      });
   });
}

const endMatchConfirm = () => {
   return new Promise(async (resolve) => {
      const score_1 = SPORT_BASE.sport_object.scoring.scoring.team_1_score.value;
      const score_2 = SPORT_BASE.sport_object.scoring.scoring.team_2_score.value;

      if (score_1 === score_2) {
         ConfirmModel({
            title: `Winner is needed`,
            text: ``,
            confirmButtonText: 'Yes',
            showCancelButton: true,
            showConfirmButton: false,
            allowOutsideClick: false,
         });
         return
      }
      const result = await ConfirmModel({
         title: `Game Completed`,
         text: `Are you sure?`,
         confirmButtonText: 'Yes',
         showCancelButton: true,
         allowOutsideClick: false,
         confirm_button_delay: 3
      });
      if (result.isConfirmed) {
         if (score_1 > score_2) {
            SPORT_BASE.sport_object.scoring.scoring.set_winner_manual(1);
         } else if (score_1 < score_2) {
            SPORT_BASE.sport_object.scoring.scoring.set_winner_manual(2);
         }

         const match = SPORT_BASE.sport_object.scoring.state.match;
         await SPORT_BASE.saveMatch(match.id, match, 'endMatchConfirm');
         await SPORT_BASE.saveGameState('match_complete');
         dbHandler.update_app_state({ update: Timestamp(), game_state: 'match_complete', });
         endGame();
      }
      resolve(true);
   });
}

const update_game_state_timestamp = () => {
   return new Promise((resolve) => {
      SPORT_BASE.updateGameStateTimestamp();
      resolve(true);
   });
}

const speech_translate = (text_key, value) => {
   const speech = SPORT_BASE.store_sport_config.speech;
   // console.log('speech_translate: ', speech[text_key]);
   const raw_text = JSON.parse(JSON.stringify(speech[text_key]));
   const text = raw_text.replace(/<#X#>/g, value);
   return text;
}

const speech = (text, save = true, voice_on = true) => {
   return new Promise((resolve) => {
      const game_count = SPORT_BASE.sport_object.scoring.state.match.games.length;
      if (voice_on) {
         if (GLOBAL_VARS.free_app === false || game_count === 1) {
            // const speechObj = WebSpeech();
            speechObj.text_to_speech(text);
         }
      }
      if (save === true) {
         if (!(GLOBAL_VARS.free_app === false || game_count === 1)) {
            text = text + "  (Free App) Sound Disabled";
         }
         dbHandler.saveSettings({
            last_message: text,
         });
      }
      resolve(true);
   });
}

const reverse_score_continuous = () => {
   return new Promise(async (resolve) => {
      const team_1_score = SPORT_BASE.sport_object.scoring.state.match.active_game.team_1_score;
      const team_2_score = SPORT_BASE.sport_object.scoring.state.match.active_game.team_2_score;
      const log_data = {
         team_1_score: team_1_score,
         team_2_score: team_2_score,
      }
      const side = SPORT_BASE.sport_object.scoring.state.serving.side;
      const Let_action = side === 1 ? 'p1swap' : 'p2swap';


      SPORT_BASE.sport_object.scoring.state.match.active_game.team_1_score = team_2_score;
      SPORT_BASE.sport_object.scoring.state.match.active_game.team_2_score = team_1_score;
      SPORT_BASE.sport_object.scoring.state.match.active_game.add_log({
         action: Let_action,
         content: 'swap-score',
         server_side: side,
         score_side: side,
         data: log_data,
      });

      SPORT_BASE.saveGameState('reverse_score_continuous');
      NAV_CONTROL.refreshScore();
      dbHandler.update_app_state({ update: Timestamp() });

      resolve(true);
   });
}

const add_10_points_continuous = () => {
   return new Promise(async (resolve) => {
      const team_1_score = SPORT_BASE.sport_object.scoring.state.match.active_game.team_1_score;
      const team_2_score = SPORT_BASE.sport_object.scoring.state.match.active_game.team_2_score;

      if (team_1_score > team_2_score) {
         SPORT_BASE.sport_object.scoring.scoring.score({ side: 2, points: 10 });
      } else if (team_1_score < team_2_score) {
         SPORT_BASE.sport_object.scoring.scoring.score({ side: 1, points: 10 });
      }

      SPORT_BASE.saveGameState('add_10_points_continuous');
      NAV_CONTROL.refreshScore();
      dbHandler.update_app_state({ update: Timestamp() });
      resolve(true);
   });
}

const clean_last_message = () => {
   return new Promise(async (resolve) => {
      dbHandler.saveSettings({
         last_message: '+--CLEAR--+',
      }).finally(async () => {
         await dbHandler.update_app_state({ update: Timestamp() });
         resolve(true);
      });
   });
}

const repeatLastMessage = () => {
   return new Promise(async (resolve) => {
      console.log('>>> repeatLastMessage <<<', SETTINGS.last_message);
      await speech(SETTINGS.last_message, false);
      resolve(true);
   });
}

const save_serving_defaults = () => {
   return new Promise(async (resolve) => {
      const manual_log = SPORT_BASE.sport_object.scoring.state.serving.manual_change_log;
      for (let a = 0; a < manual_log.length; a++) {
         SPORT_BASE.sport_object.scoring.state.serving.player_defaults[manual_log[a].player] = manual_log[a].inner_side;
      }
      SPORT_BASE.sport_object.scoring.state.serving.manual_change_log = [];
      SPORT_BASE.sport_object.scoring.state.serving.manual_change = false;
      await SPORT_BASE.saveGameState('save_serving_defaults');
      await SPORT_BASE.updateGameStateTimestamp();
      resolve(true);
   });
}

const score_undo = () => {
   return new Promise((resolve) => {
      SPORT_BASE.sport_object.scoring.scoring.undo();
      SPORT_BASE.saveGameState('score_undo');
      NAV_CONTROL.refreshScore();
      dbHandler.update_app_state({ update: Timestamp() });
      resolve(true);
   });
}

const sub_menu = (args) => {
   if (typeof OVERLAY !== 'undefined') {
      if (OVERLAY.sts === 0) {
         const optionList = args.options.map(i => {
            return {
               jsx: new ActionLink(i.jsx),
               fun: build_sport_function(i.fun),
            }
         })
         OVERLAY.element((<OverlaySlideMenu hide={OVERLAY.hide} options={optionList} ></OverlaySlideMenu>));
         OVERLAY.show();
      }
   }
}

const nav_control = (args) => {
   return new Promise((resolve) => {
      console.log('>>> nav_control: ', args);
      switch (args.value) {
         case 'open_right_menu':
            NAV_CONTROL.openRightMenu();
            break;
         case 'open_scoresheet':
            NAV_CONTROL.openRightMenuScoresheet();
            break;
         default:
            break;
      }
      resolve(true);
   });
}

const SoundsTest = () => {
   return new Promise(async (resolve) => {
      await speech('Scoring Made Easy, Sound Check', false);
      resolve(true);
   });
}


const open_login = () => {
   console.log('>>> loginTest <<<');
   if (typeof OVERLAY !== 'undefined') {
      console.log('sts: ', OVERLAY.sts);
      if (OVERLAY.sts === 0) {
         OVERLAY.element((<LoginForm commonTriggers={OVERLAY} ></LoginForm>));
         OVERLAY.show();
      }
   }
}

const logout = () => {
   console.log('Called Logout......');
   Logout();
}

const free_app_status = () => {
   if (typeof GLOBAL_VARS !== 'undefined') {
      return GLOBAL_VARS.free_app;
   }
   return true;
}

const megaPhoneTest = () => {
   console.log('navigator: ', navigator)
   if (navigator.mediaDevices) {
      navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
         handlerFunction(stream)
      }).then(() => {
         record();
         const MySwal = withReactContent(Swal)
         MySwal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Record Start',
            showConfirmButton: false,
            timer: 1500
         })

         setTimeout(function () {
            stop_record();
            const MySwal = withReactContent(Swal)
            MySwal.fire({
               position: 'top-end',
               icon: 'success',
               title: 'Record End',
               showConfirmButton: false,
               timer: 1500
            })
         }, 3000);
      });
   } else {
      const MySwal = withReactContent(Swal)
      MySwal.fire({
         position: 'top-end',
         icon: 'Error',
         title: 'No media Devices found',
         showConfirmButton: false,
         timer: 1500
      })
   }

   console.log($('.audioPlayer'))
   const recordedAudio = $('.audioPlayer').eq(0);
   var audioChunks = [];
   var rec;

   function handlerFunction(stream) {
      rec = new MediaRecorder(stream);
      rec.ondataavailable = e => {
         audioChunks.push(e.data);
         if (rec.state === "inactive") {
            let blob = new Blob(audioChunks, { type: 'audio/mpeg-3' });
            console.log('recordedAudio: ', recordedAudio)
            // console.log('recordedAudio: ', $(recordedAudio).attr())
            recordedAudio.attr('src', URL.createObjectURL(blob));
            recordedAudio.attr('controls', true);
            recordedAudio.attr('autoplay', true);

            // recordedAudio.src = URL.createObjectURL(blob);
            // recordedAudio.controls = true;
            // recordedAudio.autoplay = true;
            sendData(blob)
         }
      }
   }
   function sendData(data) { }

   const record = () => {
      console.log('Start record');
      audioChunks = [];
      rec.start();
   }
   const stop_record = () => {
      rec.stop();
      console.log('Stop record');
   }
}

const setTimer = (args) => {
   return new Promise(async (resolve) => {
      let result;
      const clockIndex = SPORT_BASE.sport_object.clocks.findIndex(clock => clock.id === args.value.id);
      if (clockIndex !== -1) {
         if (typeof args.value.init_value !== 'undefined' && args.value.init_value !== null) {
            const init_value = args.value.init_value;
            if (init_value.type === 'game_setting') {
               const linkedSetting = SPORT_BASE.sport_object.get_setting_by_key(init_value.value);
               const newTime = linkedSetting.value * 60;
               args.value.value = newTime;
            }
         }

         const newClock = new Clock_Settings(args.value);
         newClock.state = 0;
         SPORT_BASE.sport_object.clocks[clockIndex] = newClock;
         await SPORT_BASE.saveClock(newClock);
         dbHandler.update_app_state({ update: Timestamp() });
         setTimeout(async function () {
            newClock.state = 1;
            result = newClock;
            SPORT_BASE.sport_object.clocks[clockIndex] = newClock;
            await SPORT_BASE.saveClock(newClock);
            dbHandler.update_app_state({ update: Timestamp() });
            resolve({ type: 'setTimer', value: result });
         }, 100);
      } else {
         resolve({ type: 'setTimer', value: result });
      }
   });
}

const stopClock = async (type) => {
   return new Promise(async (resolve) => {
      const clockIndex = SPORT_BASE.sport_object.clocks.findIndex(clock => clock.id === type);
      if (clockIndex !== -1) {
         const clock = SPORT_BASE.sport_object.clocks[clockIndex];
         clock.state = 0;
         SPORT_BASE.sport_object.clocks[clockIndex] = clock;
         await SPORT_BASE.saveClock(clock);
      }
      resolve({ type: 'stopClock', value: type });
   });
}

const getClockTime = async (type) => {
   return new Promise(async (resolve) => {
      const clockIndex = SPORT_BASE.sport_object.clocks.findIndex(clock => clock.id === type);
      if (clockIndex !== -1) {
         const clock = SPORT_BASE.sport_object.clocks[clockIndex];
         resolve(clock.value);
      }
      resolve(0);
   });
}

const stopAllClocks = () => {
   return new Promise(async (resolve) => {
      for (let a = 0; a < SPORT_BASE.store_sport_config.clocks.length; a++) {
         await stopClock(SPORT_BASE.store_sport_config.clocks[a].id);
      }
      resolve(true);
   });
}


const check_matches_for_errors = async () => {
   console.log('>>> check_matches_for_errors <<<');
   const match_list = await dbHandler.loadAllMatches();
   console.log('match_list: ', match_list);
   const un_synced_matches = match_list.filter(i => i.synced === 0);
   for (let a = 0; a < un_synced_matches.length - 1; a++) {
      console.log('Match -- : ', match_list[a]);
   }
}

const startMatch = () => {
   return new Promise(async (resolve) => {
      await stopAllClocks();
      NAV_CONTROL.killClocks();

      if (SPORT_BASE.sport_object.game_state === 'game_complete') {
         // console.log('>>>---+ Start New Game ************');
         SPORT_BASE.sport_object.scoring.state.match.add_game();
         SPORT_BASE.sport_object.scoring.state.match.active_game.set_state(1);
         SPORT_BASE.sport_object.scoring.state.setup_config = SPORT_BASE.fresh_setup_config();
         SPORT_BASE.sport_object.scoring.state.serving.refresh_player();
      } else {
         console.warn('===STARTING NEW MATCH===');
         // check_matches_for_errors();
      }

      // >>> Sets Player Serving settings
      const server_player = SPORT_BASE.sport_object.scoring.state.serving.player;
      const player_default = SPORT_BASE.sport_object.scoring.state.serving.player_defaults[server_player];
      if (SPORT_BASE.sport_object.scoring.state.serving.player_defaults[server_player] !== null) {
         SPORT_BASE.sport_object.scoring.serving.update_serve({ inner_side: player_default });
      } else {
         SPORT_BASE.sport_object.scoring.serving.update_serve({ inner_side: 2 });
      }
      await SPORT_BASE.saveGameState('startMatch');

      // >>> Reset and Save Clocks
      SPORT_BASE.sport_object.init_self_clocks(SPORT_BASE.store_sport_config.clocks);
      for (let a = 0; a < SPORT_BASE.sport_object.clocks.length; a++) {
         await SPORT_BASE.saveClock(SPORT_BASE.sport_object.clocks[a])
      }

      if (SPORT_BASE.check_continuous_clock()) {
         console.log('START MATCH - continuous_clock');
         const continuous_clock = SPORT_BASE.getClock('continuous_clock');
         console.log('START MATCH - continuous_clock', continuous_clock);

         setTimer({ value: continuous_clock });
      }

      const gameClock = 'game_clock';
      const clockIndex = SPORT_BASE.sport_object.clocks.findIndex(clock => clock.id === gameClock);
      if (clockIndex !== -1) {
         const game_clock = SPORT_BASE.sport_object.clocks[clockIndex];
         await setTimer({ value: game_clock });
      }

      if (SPORT_BASE.sport_object.game_state === 'warmup') {
         await dbHandler.update_app_state({ update: Timestamp(), game_state: 'server_select', });
         speechFun({ value: 'play_button', text_only: true })
      } else {
         await dbHandler.update_app_state({ update: Timestamp(), game_state: 'running', });
         await clean_last_message();
      }

      stopClock('warmup_timer');
      NAV_CONTROL.refreshScore('startMatch()');

      await Sleep(1000);
      CAST_Rest_Clock();

      resolve({ type: 'startMatch', value: '' });
   });
}

const endMatchPrompt = () => {
   return new Promise(async (resolve) => {
      const playerObj = SPORT_BASE.sport_object.get_player_settings();
      const playerColor = playerObj.Match_Players.color_table;
      const teamA_name = playerObj.Match_Players.teams[0].name;
      const teamB_name = playerObj.Match_Players.teams[1].name;
      const teamA_color = playerColor[0];
      const teamB_color = playerColor[1];
      const winnerResult = await ConfirmModel({
         title: `Choose Winner`,
         text: ``,
         width: '98%',
         html: (<div className={style.chooseWinnerBox}> <ScoreTotals></ScoreTotals> </div>),
         confirmButtonText: teamA_name,
         denyButtonText: teamB_name,
         confirmButtonColor: teamA_color,
         denyButtonColor: teamB_color,
         showCancelButton: false,
         allowOutsideClick: true,
         showCloseButton: true,
         showDenyButton: true,
      });

      if (winnerResult.isConfirmed) {
         SPORT_BASE.sport_object.scoring.scoring.set_winner_manual(1);
      } else if (winnerResult.isDenied) {
         SPORT_BASE.sport_object.scoring.scoring.set_winner_manual(2);
      } else if (winnerResult.isDismissed && (winnerResult.dismiss === 'cancel' || winnerResult.dismiss === 'close' || winnerResult.dismiss === 'backdrop')) {
         resolve(false);
         return
      }

      const match = SPORT_BASE.sport_object.scoring.state.match;
      await SPORT_BASE.saveMatch(match.id, match, 'endMatchPrompt');
      await SPORT_BASE.saveGameState('match_complete');

      dbHandler.update_app_state({ update: Timestamp(), game_state: 'match_complete', });
      endGame();
      resolve(true);
   });
}

const endGame = () => {
   return new Promise(async (resolve) => {
      SPORT_BASE.sport_object.scoring.state.match.active_game.set_state(2);
      await SPORT_BASE.saveGameState('endGame');
      stopClock('game_clock');
      await clean_last_message();
      resolve({ type: 'endGame', value: '' });
   });
}

const completeMatch = async () => {
   return new Promise(async (resolve) => {
      await dbHandler.update_app_state({ update: Timestamp(), app_state: null, game_state: null, });
      resolve({ type: 'completeMatch', value: '' });
   });
}

const screenFun = (args) => {
   return new Promise(async (resolve) => {
      console.log('>>> screenFun - Clicked <<<', args);
      switch (args.value) {
         case "warmup":
            await dbHandler.update_app_state({ game_state: 'warmup' });
            break;
         default:
            break;
      }
      resolve({ type: 'screenFun', value: '' });
   });
}

const speechFun = (args) => {
   return new Promise(async (resolve) => {
      console.log('>>> speechFun - Clicked <<<', args);
      switch (args.value) {
         case 'game_start':
            const winBy = SPORT_BASE.sport_object.scoring.get_setting({ setting_name: 'win_by' }).value;
            const pointTxt = winBy > 1 ? 'points' : 'point';
            const text = `Players to win by ${winBy} ${pointTxt}`;
            if (typeof args.text_only !== 'undefined' && args.text_only === true) {
               speech(text, true, false);
            } else {
               speech(text);
            }
            break;
         case 'play_button':
            {
               let text = '';
               if (SPORT_BASE.sport_object.scoring.state.match.games.length === 1) {
                  const match_options = SPORT_BASE.sport_object.scoring.state.match.settings.match_options;
                  if (match_options === "2") {
                     const continuous_time = SPORT_BASE.sport_object.scoring.state.match.settings.continuous_time;
                     text = `Playing continuous scoring for ${continuous_time} minutes`;
                  } else {
                     const games = SPORT_BASE.sport_object.scoring.state.match.settings.games_per_match;
                     const points = SPORT_BASE.sport_object.scoring.state.match.settings.points_per_game;
                     text = `Playing ${points} points, best of ${games} games`;
                  }
               } else {
                  const side = SPORT_BASE.sport_object.scoring.state.serving.side;
                  const { team1, team2 } = SPORT_BASE.sport_object.scoring.scoring.get_score_tally;
                  if (team1 === team2) {
                     if (team1 === 1) {
                        text = `${team1}! Game all! !`;
                     } else {
                        text = `${team1}! Games all! `;
                     }
                  } else if (side === 1) {
                     text = `${team1}! Games to ${team2} !`;
                  } else if (side === 2) {
                     text = `${team2}! Games to ${team1} !`;
                  }
               }

               if (typeof args.text_only !== 'undefined' && args.text_only === true) {
                  speech(text, true, false);
               } else {
                  speech(text);
               }
            }
            break;
         case 'start_match':
            {
               const winBy = SPORT_BASE.sport_object.scoring.get_setting({ setting_name: 'win_by' }).value;
               const gamesPerMatch = SPORT_BASE.sport_object.scoring.get_setting({ setting_name: 'games_per_match' }).value;
               const pointTxt = winBy > 1 ? 'points' : 'point';
               const gamesTxt = gamesPerMatch > 1 ? 'games' : 'game';
               const text = `Players to win by ${winBy} ${pointTxt} best of ${gamesPerMatch} ${gamesTxt}`;

               if (typeof args.text_only !== 'undefined' && args.text_only === true) {
                  speech(text, true, false);
               } else {
                  speech(text);
               }
            }
            break;
         default:
            break;
      }
      resolve({ type: 'speechFun', value: '' });
   });
}

const sideSwap = (value) => {
   return new Promise(async (resolve) => {
      // console.log('>>> changeSides <<<', NAV_CONTROL.state);
      speech('Please change sides');
      resolve({ type: 'sideSwap', value: '' });
   });
}

const muteSound = () => {
   return new Promise((resolve) => {
      const value = SETTINGS.mute === true ? false : true;
      SETTINGS.mute = value;
      dbHandler.saveSettings({
         mute: value,
      });

      if (GLOBAL_VARS.cast_running) {
         CAST_Update_Screen();
      }

      resolve(true);
   });
}

export {
   build_sport_function,
   endMatchPrompt,
   stopAllClocks,
   endGame,
   speech,
   speech_translate,
   getClockTime,
   SoundsTest,
   update_game_state_timestamp,
   open_login,
   free_app_status
};