import EasySpeech from 'easy-speech';
import { SETTINGS, GLOBAL_VARS } from "../services/globalVars";
import { CAST_text_to_speech } from "../cast/cast-control";
import { Sleep, genRanID } from '../utils/utils';

var speechBuffer = [];

const WebSpeech = () => {
   const synth = window.speechSynthesis;
   // const default_voice = 'Google UK English Male'; 
   const default_voice = 'English Australia';
   var voiceList = [];
   var rate = 1;
   var pitch = 1;
   var selected_voice = default_voice;
   var volume = 100;

   const text_to_speech = (text = 'Example, Testing, Testing. 1. 2. 3') => {
      return new Promise(async (resolve) => {

         // >>> Waiting for turn in Que
         let loopCount_que = 0;
         const queID = genRanID();
         speechBuffer.push(queID);
         while (speechBuffer[0] !== queID && loopCount_que < 1000) {
            await Sleep(100);
            loopCount_que++
         }

         // >>> Waiting for Speaking to Finish
         let loopCount_status = 0;
         while (EasySpeech.status().status === "init speak" && loopCount_status < 1000) {
            await Sleep(100);
            loopCount_status++;
         }

         populateVoiceList();
         if (GLOBAL_VARS.cast_running) { CAST_text_to_speech(text); }
         EasySpeech.init({ maxTimeout: 5000, interval: 250 })
            .then(async () => {
               let voiceObject = null;
               if (SETTINGS !== false) {
                  rate = SETTINGS.speech_rate;
                  selected_voice = SETTINGS.voice !== 'unset' ? SETTINGS.voice : default_voice;
                  if (SETTINGS.mute) {
                     volume = 0;
                  } else {
                     volume = 100;
                  }
               }

               for (const voice of voiceList) {
                  if (voice.name === selected_voice) {
                     voiceObject = voice;
                  }
               }

               await EasySpeech.speak({
                  text: text,
                  voice: voiceObject,
                  pitch: parseInt(pitch),
                  rate: parseFloat(rate),
                  volume: parseInt(volume),
                  // there are more events, see the API for supported events
                  boundary: e => console.debug('boundary reached')
               });
               speechBuffer.shift();
            }).catch(e => {
               console.error(e);
               speechBuffer.shift();
            });
         resolve(true);
      });
   }

   const populateVoiceList = (lang = 'en') => {
      voiceList = [];
      const voices = synth.getVoices();
      for (let a = 0; a < voices.length; a++) {
         if (voices[a].lang.includes(lang)) {
            voiceList.push(voices[a]);
         }
      }
      return voiceList;
   }

   const populateVoiceList_promise = (lang = 'en') => {
      return new Promise((resolve) => {
         populateVoiceList();
         populateVoiceList();
         resolve(true);
      });
   }

   return { populateVoiceList, populateVoiceList_promise, text_to_speech }
}

export default WebSpeech;