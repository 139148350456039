import { genRanID } from "../utils/utils";
import { sendMessage } from "./cast-service";
import DBHandler from "../index-db/db-handler";
import { SPORT_BASE, SETTINGS } from "../services/globalVars";

const root = process.env.REACT_APP_MEDIA_HOST;
const dbHandler = DBHandler();
var update_game_clock = false;

const CAST_text_to_speech = (text) => {
   castMSG(text);
}

const CAST_Get_Settings = () => {
   return new Promise(async (resolve) => {
      const result = await castGetSettings();
      resolve(result);
   })
}

const CAST_Rest_Clock = () => {
   update_game_clock = false;
}

const CAST_Update_Screen = async () => {
   try {
      // console.warn('>>>CAST_Update_Screen<<<');
      // console.log('>>> Trigger CAST REFRESH <<<');
      // console.log('Scoring: ', SPORT_BASE.sport_object.scoring.scoring);
      // console.log('Scoring: ', SPORT_BASE.sport_object.scoring.scoring.get_score_tally);
      // console.log('SPORT_BASE: ', SPORT_BASE.sport_object.scoring.state.match.match_score);
      // new Audio('https://ia802508.us.archive.org/5/items/testmp3testfile/mpthreetest.mp3').play();

      const game_state = SPORT_BASE.sport_object.game_state;
      const warmup_timer = SPORT_BASE.getClock('warmup_timer');
      const between_timer = SPORT_BASE.getClock('between_timer');
      const match_options = SPORT_BASE.sport_object.get_setting_by_key('match_options');
      const playerObj = SPORT_BASE.sport_object.scoring.state.match.players;
      const score_left = SPORT_BASE.sport_object.scoring.state.match.active_game.team_1_score;
      const score_right = SPORT_BASE.sport_object.scoring.state.match.active_game.team_2_score;
      const { team1, team2 } = SPORT_BASE.sport_object.scoring.scoring.get_score_tally;
      const name_left = playerObj.teams[0].name;
      const name_right = playerObj.teams[1].name;
      const serving_player_1 = SPORT_BASE.sport_object.scoring.state.serving.side_state_1.serving_player - 1;
      const serving_player_2 = SPORT_BASE.sport_object.scoring.state.serving.side_state_2.serving_player - 1;
      const serving_side = SPORT_BASE.sport_object.scoring.state.serving.side === 1 ? 'L' : 'R';
      const server_side = SPORT_BASE.sport_object.scoring.state.serving.inner_side === 1 ? 'L' : 'R';
      const server_player = SPORT_BASE.sport_object.scoring.state.serving.player;
      const isDoubles = SPORT_BASE.sport_object.scoring.state.serving.type === 'doubles'? true:false;
      const p1_icon = typeof playerObj.teams[0].players[0] !== 'undefined'? playerObj.teams[0].players[0].initial:null;
      const p2_icon = typeof playerObj.teams[1].players[0] !== 'undefined'? playerObj.teams[1].players[0].initial:null;
      const p3_icon = typeof playerObj.teams[0].players[1] !== 'undefined'? playerObj.teams[0].players[1].initial:null;
      const p4_icon = typeof playerObj.teams[1].players[1] !== 'undefined'? playerObj.teams[1].players[1].initial:null;

      let color_left = '';
      let color_right = '';
      
      try {
         color_left = playerObj.teams[0].players[serving_player_1].color;
         color_right = playerObj.teams[1].players[serving_player_2].color;
         const letter_left = playerObj.teams[0].players[serving_player_1].initial;
         const letter_right = playerObj.teams[1].players[serving_player_2].initial;
         castColor('main', 'L', color_left, letter_left);
         castColor('main', 'R', color_right, letter_right);
      } catch (error) { }

      castUpdateName('L', name_left);
      castUpdateName('R', name_right);
      castUpdateScore('L', score_left);
      castUpdateScore('R', score_right);
      castUpdateWins('L', `(${team1})`);
      castUpdateWins('R', `(${team2})`);
      castUpdateIndicator(serving_side, server_side);
      castUpdateServerIcon(1, {
         doubles: isDoubles,
         icons: {
            p1: { icon: p1_icon, sts: server_player === 1 ? true:false , color: color_left },
            p2: { icon: p2_icon, sts: server_player === 2 ? true:false, color: color_right },
            p3: { icon: p3_icon, sts: server_player === 3 ? true:false, color: color_left },
            p4: { icon: p4_icon, sts: server_player === 4 ? true:false, color: color_right },
         }
      });


      if (update_game_clock === false) {
         update_game_clock = true;
         const _loadClocks = await SPORT_BASE.loadClocks();
         if (match_options.value === 2) {
            const continuous_clock = SPORT_BASE.getClock('continuous_clock');
            castUpdateClockTimer('start', continuous_clock.value, 1);
            console.log('SENDING CLOCK TIME continuous_clock: ', continuous_clock.value);
         } else {
            const game_clock = SPORT_BASE.getClock('game_clock');
            console.log('SENDING CLOCK TIME: ', game_clock.value);
            castUpdateClockTimer('start', game_clock.value);
         }
      }

      if (game_state === 'running') {
         castWarmupScreen('false', 0, '');
         castGameComplete('false', [], 0);
      } else if (game_state === 'warmup') {
         const adArray = {
            ad0: '',
            ad1: '',
            ad2: '',
            ad3: ''
         }
         castGameComplete('false', [], 0);
         castWarmupScreen('true', warmup_timer.value, adArray);
         // castUpdateClockTimer('stop', 0);
      } else if (game_state === 'game_complete' || game_state === 'match_complete' || game_state === 'init' || game_state === null) {
         const p1Score = [];
         const p2Score = [];
         const match_score = SPORT_BASE.sport_object.scoring.state.match.match_score;
         for (let a = 0; a < match_score.length; a++) {
            p1Score.push(match_score[a].A);
            p2Score.push(match_score[a].B);
         }
         let score = { p1: p1Score, p2: p2Score, ad1: 'test.png', ad2: 'test.png', ad3: 'test.png', ad0: 'test.png' }
         if (game_state === 'match_complete' || game_state === 'init' || game_state === null) {
            castGameComplete('match', score, 0);
         } else {
            castGameComplete('game', score, between_timer.value);
         }
      } else {
         castWarmupScreen('false', 0, '');
         castGameComplete('false', [], 0);
      }

      castUpdateADs();
      castUpdateName('L', name_left);
      castUpdateName('R', name_right);
      castSpeechMute(SETTINGS.mute);
      castVoice(SETTINGS.cast_voice);
      castBackground(SETTINGS.background);
      castBorder(SETTINGS.cast_border_size);
      castSpeechRate(SETTINGS.cast_speech_rate);
   } catch (error) {
      console.error('CAST_Update_Screen: ', error);
   }
}

const castUpdateADs = async () => {
   const club = await dbHandler.load_club();
   if (typeof club !== 'undefined') {
      let object = {
         'ads': {
            ad0: root + club.ad_1.substring(3),
            ad1: root + club.ad_2.substring(3),
            ad2: root + club.ad_3.substring(3),
            ad3: root + club.club_logo,
         },
      };
      // let adArray = {
      //    ad0: root+club.ad_1.substring(3),
      //    ad1: root+club.ad_2.substring(3),
      //    ad2: root+club.ad_3.substring(3),
      //    ad3: root+club.club_logo,
      // }
      // object.ads = adArray;
      castUpdateSettings(object, null, null);
   }
}

const castMSG = (msg) => { castUpdateText('msg', msg, ''); }
const castBorder = (size) => { castUpdateText('border', size, ''); }
const castVoice = (value) => { castUpdateText('voice', value, ''); }
const castUpdateName = (side, name) => { castUpdateText('name', side, name); }
const castUpdateWins = (side, wins) => { castUpdateText('wins', side, wins); }
const castBackground = (theme) => { castUpdateText('background', theme, ''); }
const castSpeechRate = (value) => { castUpdateText('speechRate', value, ''); }
const castSpeechMute = (value) => { castUpdateText('mute', value, ''); }
const castUpdateScore = (side, score) => { castUpdateText('score', side, score); }
const castColor = (object, side, color, symbol = null) => { castUpdatecolor(object, side, color, symbol); }
const castUpdateIndicator = (side, indicator) => { castUpdateText('indicator', side, indicator); }
const castUpdateServerIcon = (side, details) => { castUpdateText('serverIcon', side, details); }
const castWarmupScreen = (status, time, other) => { castUpdateWarmupScreen(status, time, other); }
const castGameComplete = (status, score = [], time) => { castUpdateGameComplete(status, score, time); }
const castUpdateClockTimer = (status, time = 0, dir = 0) => { castUpdateClock('timer', status, time, dir); }

const castUpdateText = (object, side, change) => {
   let controlObject = objectBulderText('updateText', object, side, change);
   let controlArray = [controlObject];
   sendControlObject(controlArray);
}

const castUpdateClock = (object, status, time, dir = 0) => {
   let controlObject = objectBulderText('updateClock', object, status, time, dir);
   let controlArray = [controlObject];
   sendControlObject(controlArray);
}

const castUpdateGameComplete = (status, score, time) => {
   let controlObject = objectBulderText('gameComplete', status, score, time);
   let controlArray = [controlObject];
   sendControlObject(controlArray);
}

const castUpdateWarmupScreen = (status, time, other) => {
   let controlObject = objectBulderText('warmup', status, time, other);
   let controlArray = [controlObject];
   sendControlObject(controlArray);
}

const castUpdatecolor = (object, side, color, symbol) => {
   let controlObject = objectBulderText('color', object, side, color, symbol);
   let controlArray = [controlObject];
   sendControlObject(controlArray);
}

const castUpdateSettings = (object, side, color) => {
   let controlObject = objectBulderText('SETTING', object, side, color);
   let controlArray = [controlObject];
   sendControlObject(controlArray);
}

const castGetSettings = () => {
   return new Promise((resolve) => {
      let controlObject = objectBulderText('GET_SETTING', null, null, null);
      let controlArray = [controlObject];
      const result = sendControlObject(controlArray, true);
      resolve(result);
   })
}

const objectBulderText = (control, object, side, change, extra = null) => {
   let controlOBJ = {
      control: control,
      object: object,
      side: side,
      change: change,
      extra: extra,
      message_id: genRanID(),
   }
   return controlOBJ;
}

const sendControlObject = (controlArray, await_result = false) => {
   return new Promise(async (resolve) => {
      try {
         // console.log(controlArray);
         const result = await sendMessage({
            data: 'Control',
            control: controlArray
         }, await_result);
         resolve(result);
      } catch (error) {
         resolve(false);
      }
   })
}

export {
   CAST_Update_Screen,
   CAST_text_to_speech,
   CAST_Get_Settings,
   CAST_Rest_Clock
}