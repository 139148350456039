import Input from "../../../components/form/input/input";
import ColorBox from "../../../components/form/color-box/color-box";
import InputWrap from "../../../components/form/input-wrap/input-wrap";
import RadioSelect from "../../../components/form/radio-select/radio-select";
import InputCounter from "../../../components/form/Input-counter/Input-counter";
import { Timestamp, genKeyRan, IsSwalOpen } from "../../utils/utils";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CastButton from "../../../components/chrome-cast/cast-button";
import { speech, speech_translate, build_sport_function } from '../sport-function';


import {
   faPlay,
   faArrowRotateLeft,
   faArrowsRotate,
   faPersonRunning,
   faFaceGrinTongueWink,
   faTriangleExclamation,
   faHandBackFist,
   faHandPointer,
   faHandSpock,
   faThumbsDown,
   faBullhorn,
   faVolumeHigh,
   faCircleUser,
   faVolumeXmark,
   faRotateRight,
   faStopwatch,
   faBell,
   faCircleStop,
   faFloppyDisk,
   faNoteSticky,
   faBinoculars,
   faHand,
   faMobile,
   faRepeat,
   faDatabase,
   faCirclePlus
} from '@fortawesome/free-solid-svg-icons';
import { useActionState } from "react";

class Sport {
   sport_name = 'sport'; // property
   settings = {};
   scoring = {
      sides: 0
   };
   match_settings = {}

   // constructor() { // constructor
   //    // console.log('Sport constructor....'); 
   //    // console.log('sport_name: ', this.sport_name);
   // }

   // method(...) { } // method
   // get something(...) { } // getter method
   // set something(...) { } // setter method
   // [Symbol.iterator]() { } // method with computed name (symbol here)
}

const string_to_class = (className, args) => {
   // console.log('>>>>>>>>>>>> string_to_class <<<<<<<<<<<<: ', className);
   switch (className) {
      case 'PlayerSettings':
         return new PlayerSettings(args);
      case 'RadioSetting':
         return new RadioSetting(args);
      case 'InputCountSetting':
         return new InputCountSetting(args);
      default:
         return new SportSettingElement();
   }
}


const string_to_icon = (iconText) => {
   switch (iconText) {
      case 'faPlay':
         return (<FontAwesomeIcon icon={faPlay} />)
      case 'faArrowRotateLeft':
         return (<FontAwesomeIcon icon={faArrowRotateLeft} />)
      case 'faArrowsRotate':
         return (<FontAwesomeIcon icon={faArrowsRotate} />)
      case 'faPersonRunning':
         return (<FontAwesomeIcon icon={faPersonRunning} />)
      case 'faFaceGrinTongueWink':
         return (<FontAwesomeIcon icon={faFaceGrinTongueWink} />)
      case 'faTriangleExclamation':
         return (<FontAwesomeIcon icon={faTriangleExclamation} />)
      case 'faHandBackFist':
         return (<FontAwesomeIcon icon={faHandBackFist} />)
      case 'faHandPointer':
         return (<FontAwesomeIcon icon={faHandPointer} />)
      case 'faHandSpock':
         return (<FontAwesomeIcon icon={faHandSpock} />)
      case 'faThumbsDown':
         return (<FontAwesomeIcon icon={faThumbsDown} />)
      case 'faBullhorn':
         return (<FontAwesomeIcon icon={faBullhorn} />)
      case 'faVolumeHigh':
         return (<FontAwesomeIcon icon={faVolumeHigh} />)
      case 'faCircleUser':
         return (<FontAwesomeIcon icon={faCircleUser} />)
      case 'faVolumeXmark':
         return (<FontAwesomeIcon icon={faVolumeXmark} />)
      case 'faRotateRight':
         return (<FontAwesomeIcon icon={faRotateRight} />)
      case 'faStopwatch':
         return (<FontAwesomeIcon icon={faStopwatch} />)
      case 'faBell':
         return (<FontAwesomeIcon icon={faBell} />)
      case 'faCircleStop':
         return (<FontAwesomeIcon icon={faCircleStop} />)
      case 'faFloppyDisk':
         return (<FontAwesomeIcon icon={faFloppyDisk} />)
      case 'faNoteSticky':
         return (<FontAwesomeIcon icon={faNoteSticky} />)
      case 'faBinoculars':
         return (<FontAwesomeIcon icon={faBinoculars} />)
      case 'faHand':
         return (<FontAwesomeIcon icon={faHand} />)
      case 'faMobile':
         return (<FontAwesomeIcon icon={faMobile} />)
      case 'faRepeat':
         return <FontAwesomeIcon icon={faRepeat} />
      case 'faDatabase':
         return <FontAwesomeIcon icon={faDatabase} />
      case 'cast_btn':
         return (<CastButton>  No Cast </CastButton>)
      case 'faCirclePlus':
         return <FontAwesomeIcon icon={faCirclePlus} />
      default:
         return ('')
   }
}

const convert_score_action = (type, parent) => {
   switch (type) {
      case 'standard':
         return new Score_Standard(parent);
      case 'pickleball':
         return new Score_Pickleball(parent);
      default:
         return Score_Action(parent);
   }
}

const convert_serving_action = (type, parent, config) => {
   switch (type) {
      case 'standard':
         return new Serving_Action(parent, config);
      case 'pickleball':
         return new Serving_Action_Pickleball(parent, config);
      default:
         return null;
   }
}

const convert_serving_state = (type, args) => {
   // console.log('DEBUG - convert_serving_state - type: ', type);
   switch (type) {
      case 'standard':
         return new Serving_State(args);
      case 'pickleball':
         return new Serving_State_Pickleball(args);
      default:
         return new Serving_State(args);
   }
}



class Serving_Action {
   // parent;
   config;
   // last_fun = null;   // Can be Replace by Match Log.....

   constructor(parent, config) {
      // this.parent = parent;

      // eslint-disable-next-line no-undef
      this.parent_ref = new WeakRef(parent);
      this.config = config;
   }

   get parent() {
      return this.parent_ref.deref();
   }

   serve = (side = null) => {
      // >>> Reset Save Side
      this.parent.state.serving.manual_change = false;
      if (typeof this.config.handout !== 'undefined') {
         if (this.config.handout.type === 'point') {
            if (this.is_handout(side)) {
               this.cycle_player(side);
               const defaultCheck = this.parent.state.serving.player_defaults[this.parent.state.serving.player];
               if (typeof defaultCheck === 'number') {
                  this.parent.state.serving.inner_side = defaultCheck;
               } else {
                  this.parent.state.serving.inner_side = this.config.handout_default.value;
               }
            } else {
               if (this.parent.state.serving.inner_side === 1) {
                  this.parent.state.serving.inner_side = 2;
               } else {
                  this.parent.state.serving.inner_side = 1;
               }
            }
            this.parent.state.serving.side = side;
         } else {
            console.warn('Handout Type not supported');
         }
      }
   }

   update_serve = ({ side, inner_side, player, serving_side_1, serving_side_2 }) => {
      this.parent.state.serving.updatePlayer(player);
      if (typeof side !== 'undefined') { this.parent.state.serving.side = side; }
      if (typeof player !== 'undefined') { this.parent.state.serving.player = player; }
      if (typeof inner_side !== 'undefined') { this.parent.state.serving.inner_side = inner_side; }
      if (typeof serving_side_1 !== 'undefined') { this.parent.state.serving.side_state_1.serving_side = serving_side_1; }
      if (typeof serving_side_2 !== 'undefined') { this.parent.state.serving.side_state_2.serving_side = serving_side_2; }
   }

   cycle_player_default = (side) => {
      const max = this.parent.state.serving.type === 'doubles' ? 4 : 2;
      this.parent.state.serving.player++;
      if (this.parent.state.serving.player > max) {
         this.parent.state.serving.player = 1;
      }

      if (this.parent.state.serving.type === 'doubles') {
         if (side === 1) {
            if (this.parent.state.serving.side_state_1.serving_player === 1) {
               this.parent.state.serving.side_state_1.serving_player = 2;
            } else {
               this.parent.state.serving.side_state_1.serving_player = 1;
            }
         } else if (side === 2) {
            if (this.parent.state.serving.side_state_2.serving_player === 1) {
               this.parent.state.serving.side_state_2.serving_player = 2;
            } else {
               this.parent.state.serving.side_state_2.serving_player = 1;
            }
         }

         if (side === 1 && this.parent.state.serving.side_state_1.serving_side === 1) {
            this.parent.state.serving.side_state_1.serving_side = 2;
         } else if (side === 1 && this.parent.state.serving.side_state_1.serving_side === 2) {
            this.parent.state.serving.side_state_1.serving_side = 1;
         } else if (side === 2 && this.parent.state.serving.side_state_2.serving_side === 1) {
            this.parent.state.serving.side_state_2.serving_side = 2;
         } else if (side === 2 && this.parent.state.serving.side_state_2.serving_side === 2) {
            this.parent.state.serving.side_state_2.serving_side = 1;
         }
      }
   }


   cycle_player = (side) => {
      return this.cycle_player_default(side);
   }

   is_handout = (side) => {
      return this.parent.state.serving.side !== side ? true : false;
   }
}


class Serving_Action_Pickleball extends Serving_Action {
   constructor(parent, config) {
      super(parent, config);
   }

   cycle_player = (side) => {
      if (this.parent.state.serving.type === 'doubles') {
         const handout = this.is_handout(side);
         const side_out = this.is_side_out(side);
         if (side_out && side === 1) {
            this.parent.state.serving.side_state_2.serving_player = 1;
         } else if (side_out && side === 2) {
            this.parent.state.serving.side_state_1.serving_player = 1;
         } else if (handout && side === 1) {
            this.parent.state.serving.side_state_2.serving_player = 2;
         } else if (handout && side === 2) {
            this.parent.state.serving.side_state_1.serving_player = 2;
         }
      }
      this.cycle_player_default(side);
   }


   is_side_out = (side) => {
      const handout = this.is_handout(side);
      const game_options = this.parent.get_setting({ setting_name: 'game_options' });
      if (game_options.value === 1 && this.parent.state.serving.type === 'doubles') {
         if (handout && side === 2 && this.parent.state.serving.side_state_1.serving_side === 2) {
            console.warn('Side 1 - Move to Calling Side Out!!!');
            return true;
         } else if (handout && side === 1 && this.parent.state.serving.side_state_2.serving_side === 2) {
            console.warn('Side 2 - Move to Calling Side Out!!!');
            return true;
         }
      } else {
         return handout;
      }
      return false;
   }

   serve = (side = null) => {
      // >>> Reset Save Side
      this.parent.state.serving.manual_change = false;
      if (typeof this.config.handout !== 'undefined') {
         if (this.config.handout.type === 'point') {
            const handout = this.is_handout(side);
            const game_options = this.parent.get_setting({ setting_name: 'game_options' });
            const side_out = this.is_side_out(side);

            if (game_options.value === 1 && this.parent.state.serving.type === 'doubles') {

               if (side_out) {
                  this.cycle_player(side);
                  this.parent.state.serving.side = side;
                  this.parent.state.serving.inner_side = 2;
               } else if (handout && side === 1) {
                  this.cycle_player(2);
                  this.parent.state.serving.inner_side = 2;
               } else if (handout && side === 2) {
                  this.cycle_player(1);
                  this.parent.state.serving.inner_side = 2;
               } else {
                  if (this.parent.state.serving.inner_side === 1) {
                     this.parent.state.serving.inner_side = 2;
                  } else {
                     this.parent.state.serving.inner_side = 1;
                  }
               }
            } else {
               this.cycle_player(side);
               this.parent.state.serving.side = side;
               if (side === 1) {
                  if (this.parent.state.match.active_game.team_1_score % 2 === 0) {
                     this.parent.state.serving.inner_side = 2; // Even == Right
                  } else {
                     this.parent.state.serving.inner_side = 1; // Odd == Left
                  }
               } else if (side === 2) {
                  if (this.parent.state.match.active_game.team_2_score % 2 === 0) {
                     this.parent.state.serving.inner_side = 2; // Even == Right
                  } else {
                     this.parent.state.serving.inner_side = 1; // Odd == Left
                  }
               }
            }
         }
      }
   }
}


class Score_Action {
   // parent;
   won = false;
   match_won = false;

   team_1_score;
   team_2_score;
   match_state = 'init';

   constructor(parent) {
      // this.parent = parent;

      // eslint-disable-next-line no-undef
      this.parent_ref = new WeakRef(parent);

      this.score_conditions();
   }

   get parent() {
      return this.parent_ref.deref();
   }

   score_triggers = ({ side }) => {
      // console.log('>>> score_triggers <<<');
      if (this.parent.serving !== null) {
         this.parent.serving.serve(side);
      }
   }

   score_conditions = () => {
      // console.log('>>> score_conditions <<<');
      const won = this.win_condition();
      if (won) {
         this.match_won = this.match_win_condition();
      }
   }

   win_condition = () => { return false; }
   match_win_condition = () => { return false; }
   score = () => { console.log('trigger Score'); };
   undo = () => { console.log('trigger undo'); }

   game_win_condition_win_by = (team_1_score, team_2_score, points_per_game, win_by) => {
      if (team_1_score >= points_per_game && team_1_score >= (team_2_score + win_by)) {
         return 1;
      } else if (team_2_score >= points_per_game && team_2_score >= (team_1_score + win_by)) {
         return 2;
      }
      return 0;
   }

   game_win_condition_win_by_with_max = (team_1_score, team_2_score, points_per_game, win_by, maximum_score) => {
      if (team_1_score >= maximum_score || (team_1_score >= points_per_game && team_1_score >= (team_2_score + win_by))) {
         return 1;
      } else if (team_2_score >= maximum_score || (team_2_score >= points_per_game && team_2_score >= (team_1_score + win_by))) {
         return 2;
      }
      return 0;
   }

}

class Score_Standard extends Score_Action {
   // team_1_score;
   // team_2_score;
   // match_state = 'init';

   constructor(parent) {
      super(parent);
      this.team_1_score = new Score();
      this.team_2_score = new Score();
      this.team_1_score.value = this.parent.state.match.active_game.team_1_score;
      this.team_2_score.value = this.parent.state.match.active_game.team_2_score;
      this.score_conditions();
   }

   score = ({ side, points = 1 }) => {
      const handout = this.parent.serving.is_handout(side);
      const server_side = JSON.parse(JSON.stringify(this.parent.state.serving.side));
      const server_player = JSON.parse(JSON.stringify(this.parent.state.serving.player));
      const inner_side = JSON.parse(JSON.stringify(this.parent.state.serving.inner_side));

      this.update_match_state('running');

      if (side === 1) {
         this.team_1_score.add(points);
      } else if (side === 2) {
         this.team_2_score.add(points);
      }

      this.update_state({
         addLog: true,
         score_side: side,
         server_side: server_side,
         server_player: server_player,
         server_inner_side: inner_side,
         handout: handout,
         points: points,
      });
      this.score_conditions({ side });
      this.score_triggers({ side });

      this.call_score(handout, side);
      return { handout: handout }
   };

   undo = () => {
      const log = JSON.parse(JSON.stringify(this.parent.state.match.active_game.remove_log_last()));
      // console.log('Undo log: ', log);
      // console.log('>>>>----+ Undo log: ', log);

      this.parent.serving.update_serve({
         side: log.server_side,
         player: log.server_player,
         inner_side: log.server_inner_side,
         serving_side_1: typeof log.serving_side_1 !== 'undefined' ? log.serving_side_1 : 0,
         serving_side_2: typeof log.serving_side_2 !== 'undefined' ? log.serving_side_2 : 0,
      });

      if (log.content == 'swap-score') {
         const team_1_score = this.team_1_score.value;
         const team_2_score = this.team_2_score.value;
         this.team_1_score.value = team_2_score;
         this.team_2_score.value = team_1_score;
      } else if (log.score_side === 1) {
         if (log.action.includes('P+')) {
            this.team_1_score.sub(parseInt(log.action.split('P+')[1]));
         } else if (log.action === 'S') { // Skip Score 
            // this.team_1_score.sub(1);
         } else {
            this.team_1_score.sub(1);
         }
      } else if (log.score_side === 2) {
         if (log.action.includes('P+')) {
            this.team_2_score.sub(parseInt(log.action.split('P+')[1]));
         } else if (log.action === 'S') { // Skip Score
            // this.team_2_score.sub(1);
         } else {
            this.team_2_score.sub(1);
         }
      }
      this.update_game_won('');
   }

   get get_score_tally() {
      let p1w = 0;
      let p2w = 0;
      for (let a = 0; a < this.parent.state.match.games.length; a++) {
         const winner = this.who_won(this.parent.state.match.games[a]);
         if (winner === 1) {
            p1w++;
         } else if (winner === 2) {
            p2w++;
         } else if (winner === false) {
            console.error('match_win_condition FALSE ERROR');
         }
      }
      return { team1: p1w, team2: p2w };
   }

   win_condition = () => {
      // TODO: win_condition Added Continuous Mode.
      const whoWon = this.who_won(this.parent.state.match.active_game);
      if (whoWon !== 0) {
         this.update_game_won(whoWon);
         return true;
      }
      this.won = false;
      return false;
   }


   pre_win_condition = () => {
      const tempGame = JSON.parse(JSON.stringify(this.parent.state.match.active_game));
      if (tempGame.team_1_score > tempGame.team_2_score) {
         tempGame.team_1_score++;
      } else if (tempGame.team_1_score < tempGame.team_2_score) {
         tempGame.team_2_score++;
      } else {
         tempGame.team_1_score++;
      }
      const whoWon = this.who_won(tempGame);
      return whoWon;
      // if (whoWon !== 0) { return true; }
      // return false;
   }

   pre_match_win_condition = () => {
      let win_result = null;
      let { team1, team2 } = JSON.parse(JSON.stringify(this.get_score_tally));
      const gameOptions = this.parent.get_setting({ setting_name: 'game_options' });
      if (team1 > team2) {
         team1++;
      } else {
         team2++;
      }
      if (gameOptions.value === 2) {
         win_result = this.total_of_match_win(team1, team2);
      } else {
         win_result = this.best_of_match_win(team1, team2);
      }
      return win_result.match_won;
   }

   match_win_condition = () => {
      if (this.parent.state.match.winner !== '') {
         this.update_match_state('match_won');
         return true;
      } else {
         const gameOptions = this.parent.get_setting({ setting_name: 'game_options' });

         const { team1, team2 } = this.get_score_tally;
         let win_result = null;
         if (gameOptions.value === 2) {
            win_result = this.total_of_match_win(team1, team2);
         } else {
            win_result = this.best_of_match_win(team1, team2);
         }

         if (win_result.match_won) {
            if (win_result.side === 1) {
               this.update_match_state('match_won', 1);
            } else if (win_result.side === 2) {
               this.update_match_state('match_won', 2);
            }
            this.match_won = true;
            return true;
         }

      }
      this.match_won = false;
      return false;
   }

   total_of_match_win = (team1, team2) => {
      const result = { match_won: false, side: 0, };
      const gamesPerMatch = this.parent.get_setting({ setting_name: 'games_per_match' });
      if (team1 + team2 >= gamesPerMatch.value) {
         if (team1 > team2) {
            result.side = 1;
         } else if (team1 < team2) {
            result.side = 2;
         }
         result.match_won = true;
      }
      return result;
   }

   best_of_match_win = (team1, team2) => {
      const result = { match_won: false, side: 0, };
      const gamesPerMatch = this.parent.get_setting({ setting_name: 'games_per_match' });
      const p1Pct = ((team1 / gamesPerMatch.value) * 100);
      const p2Pct = ((team2 / gamesPerMatch.value) * 100);
      if (p1Pct > 50 || p2Pct > 50) {
         if (team1 > team2) {
            result.side = 1;
         } else if (team1 < team2) {
            result.side = 2;
         }
         result.match_won = true;
      }
      return result;
   }

   who_won = (game) => {
      // console.log('Who Won -- game: ', game.winner);
      if (game.winner !== '') {
         return game.winner;
      } else {
         let winner = 0;
         const team_1_score = game.team_1_score;
         const team_2_score = game.team_2_score;
         const win_by = this.parent.get_setting({ setting_name: 'win_by' });
         const match_options = this.parent.get_setting({ setting_name: 'match_options' });
         const points_per_game = this.parent.get_setting({ setting_name: 'points_per_game' });
         if (match_options.value !== 2) {
            winner = this.game_win_condition_win_by(team_1_score, team_2_score, points_per_game.value, win_by.value);
         }

         return winner;
      }
   }

   set_winner_manual = (winner_player) => {
      this.update_game_won(winner_player);
      this.update_match_state('match_won', winner_player);
   }

   update_state = ({ addLog, score_side, server_side, server_player, server_inner_side, handout, points, serving_side_1, serving_side_2 }) => {
      // console.log('>>> update_state <<<: ', addLog, score_side, server_side, server_player, server_inner_side, handout, points);
      let logData;

      let content = '';
      if (handout === true) {
         content = 'HO';
      }

      if (typeof addLog !== 'undefined' && addLog === true) {
         let action = 'P';
         if (points > 1) {
            action = 'P+' + points;
         } else if (points === 0) {
            action = 'S'; // S for Skip....
         }

         logData = {
            server_player: server_player,
            score_side: score_side,
            server_side: server_side,
            server_inner_side: server_inner_side,
            content: content,
            action: action,
            serving_side_1: serving_side_1,
            serving_side_2: serving_side_2,
         }
      }

      this.parent.state.match.update_active_game({
         match_state: this.match_state,
         team_1_score: this.team_1_score.value,
         team_2_score: this.team_2_score.value,
         logData
      });
   }

   update_match_state = (state = 'init', match_winner) => {
      this.match_state = state;
      this.parent.state.match.update_active_game({
         match_state: state,
         match_winner: match_winner,
      });
   }

   update_game_won = (player = '') => {
      if (player === '') {
         this.won = false;
         this.update_match_state('running', '');
      } else {
         this.won = true;
         this.update_match_state('game_won');
      }
      this.parent.state.match.update_active_game({
         winner: player,
      });

      this.update_state({});

      // Add Log of Match won!
   }

   last_score_side = () => {
      const last_log = this.parent.state.match.active_game.get_log_last();

      if (last_log !== false) {
         return last_log.score_side;
      } else {
         return false;
      }
   }


   call_score = (handout, side) => {
      const textToSpeech = true;
      if (textToSpeech) {
         const win_by = this.parent.get_setting({ setting_name: 'win_by' });
         const match_options = this.parent.get_setting({ setting_name: 'match_options' });
         const points_per_game = this.parent.get_setting({ setting_name: 'points_per_game' });
         const point_1 = this.team_1_score.value === 0 ? 'Zero' : this.team_1_score.value;
         const point_2 = this.team_2_score.value === 0 ? 'Zero' : this.team_2_score.value;
         let text = side === 1 ? point_1 + '!  ' + point_2 : point_2 + '!  ' + point_1;
         if (point_1 === point_2) { text = point_1 + ' all'; }

         if (handout) { speech(speech_translate('handout')); }

         if (handout) {
            speech(text, false);
         } else {
            speech(text);
         }

         if (!this.won) {
            const pre_win_condition = this.pre_win_condition();
            if (pre_win_condition) {
               const pre_match_win_condition = this.pre_match_win_condition();
               const score_tally = this.get_score_tally;
               if (((side === 1 && pre_win_condition === 1 && score_tally.team1 >= score_tally.team2) || (side === 2 && pre_win_condition === 2 && score_tally.team2 >= score_tally.team1)) && pre_match_win_condition) {
                  // speech("Match Ball");
                  speech(speech_translate('match_ball'));
               } else if (pre_match_win_condition && (((side === 2 && score_tally.team2 <= score_tally.team1) && pre_win_condition === 1) || ((side === 1 && score_tally.team1 <= score_tally.team2) && pre_win_condition === 2))) {
                  speech(speech_translate('match_ball'));
               } else {
                  speech(speech_translate('game_ball'));
               }
            }
         }

         if (typeof match_options != 'undefined' && match_options != 2) {
            if (typeof this.parent.state.setup_config.players_to_win_by !== 'undefined' && this.parent.state.setup_config.players_to_win_by.value === true) {
               const playerWinByCall = point_1 >= points_per_game.value - 1 && point_2 >= points_per_game.value - 1 ? true : false;
               if (playerWinByCall) {
                  speech(speech_translate('win_by_call', win_by.value));
                  this.parent.state.setup_config.players_to_win_by.value = false;
               }
            }
         }
      }
   }


}


// class Score_Pickleball extends Score_Action {
class Score_Pickleball extends Score_Standard {

   constructor(parent) {
      super(parent);
      this.team_1_score = new Score();
      this.team_2_score = new Score();
      this.team_1_score.value = this.parent.state.match.active_game.team_1_score;
      this.team_2_score.value = this.parent.state.match.active_game.team_2_score;
      this.score_conditions();
   }


   score = ({ side, points = 1 }) => {
      try {
         // console.log('DEBUG - Score_Pickleball - score: ', side, points);
         const handout = this.parent.serving.is_handout(side);
         const server_side = JSON.parse(JSON.stringify(this.parent.state.serving.side));
         const server_player = JSON.parse(JSON.stringify(this.parent.state.serving.player));
         const inner_side = JSON.parse(JSON.stringify(this.parent.state.serving.inner_side));
         const game_options = this.parent.get_setting({ setting_name: 'game_options' });
         const score_freeze = this.parent.get_setting({ setting_name: 'score_freeze' });
         const points_per_game = this.parent.get_setting({ setting_name: 'points_per_game' });
         const freeze = typeof score_freeze !== 'undefined' ? score_freeze.value : 0;
         const serving_side_1 = this.parent.state.serving.side_state_1.serving_side;
         const serving_side_2 = this.parent.state.serving.side_state_2.serving_side;
         // >>> Freeze Score Condition - (20) <<<
         const p1_freeze_condition = (side === 1 && this.team_1_score.value >= (points_per_game.value - 1));
         const p2_freeze_condition = (side === 2 && this.team_2_score.value >= (points_per_game.value - 1));
         // >>> Freeze Score Condition - (20/18) <<<
         const p1_freeze_condition_2_1 = (side === 1 && this.team_1_score.value >= (points_per_game.value - 1));
         const p2_freeze_condition_2_1 = (side === 2 && this.team_2_score.value >= (points_per_game.value - 1));
         const p1_freeze_condition_2_2 = (side === 1 && this.team_2_score.value >= (points_per_game.value - 1) && this.team_1_score.value >= (points_per_game.value - 2));
         const p2_freeze_condition_2_2 = (side === 2 && this.team_1_score.value >= (points_per_game.value - 1) && this.team_2_score.value >= (points_per_game.value - 2));
         const freeze_score = (freeze === 1 && (p1_freeze_condition || p2_freeze_condition) && side !== server_side);
         const freeze_score_2 = (freeze === 2 && (p1_freeze_condition_2_1 || p2_freeze_condition_2_1 || p1_freeze_condition_2_2 || p2_freeze_condition_2_2) && side !== server_side);
         let log_points = points;

         // This line checks three conditions to determine if a score should be added.
         // 1. If Traditional and the scoring side is the same as the server side.
         // 2. Or If Rally is active and there is no score freeze.
         // 3. Or If Rally is active and there is no freeze condition met.
         if ((side === server_side && game_options.value === 1) || (game_options.value === 2 && freeze === 0) || (game_options.value === 2 && freeze === 1 && !freeze_score) || (game_options.value === 2 && freeze === 2 && !freeze_score_2)) {
            if (side === 1) {
               this.team_1_score.add(points);
            } else if (side === 2) {
               this.team_2_score.add(points);
            } else {
               log_points = 0;
            }
         } else {
            log_points = 0;
         }

         const side_out = this.parent.serving.is_side_out(side);

         this.update_match_state('running');
         this.update_state({
            addLog: true,
            score_side: side,
            server_side: server_side,
            server_player: server_player,
            server_inner_side: inner_side,
            handout: handout,
            points: log_points,
            serving_side_1: serving_side_1,
            serving_side_2: serving_side_2,
         });
         this.score_conditions({ side });
         this.score_triggers({ side });
         this.call_score(side_out, side);
         return { handout: handout }
      } catch (error) {
         console.log('DEBUG - Score_Pickleball - score - error: ', error);
      }
   };

   who_won = (game) => {
      // console.log('DEBUG - Score_Pickleball - who_won');
      if (game.winner !== '') {
         return game.winner;
      } else {
         let winner = 0;
         const team_1_score = game.team_1_score;
         const team_2_score = game.team_2_score;
         const win_by = this.parent.get_setting({ setting_name: 'win_by' });
         const match_options = this.parent.get_setting({ setting_name: 'match_options' });
         const points_per_game = this.parent.get_setting({ setting_name: 'points_per_game' });
         const maximum_score_opt = this.parent.get_setting({ setting_name: 'maximum_score_opt' });
         const maximum_score = this.parent.get_setting({ setting_name: 'maximum_score' });
         const deciding_match = this.parent.get_setting({ setting_name: 'deciding_match' });
         const games_per_match = this.parent.get_setting({ setting_name: 'games_per_match' });
         const total_games = this.parent.state.match.games.length;

         if (match_options.value !== 2) {
            if (deciding_match.value === 1 && total_games === games_per_match.value) {
               const tb_points = this.parent.get_setting({ setting_name: 'tb_points' });
               const tb_win_by = this.parent.get_setting({ setting_name: 'tb_win_by' });
               winner = this.game_win_condition_win_by(team_1_score, team_2_score, tb_points.value, tb_win_by.value);
            } else if (maximum_score_opt.value === 1) {
               winner = this.game_win_condition_win_by_with_max(team_1_score, team_2_score, points_per_game.value, win_by.value, maximum_score.value);
            } else {
               winner = this.game_win_condition_win_by(team_1_score, team_2_score, points_per_game.value, win_by.value);
            }
         }
         return winner;
      }
   }

   call_score = (handout, side) => {
      try {
         const textToSpeech = true;
         if (textToSpeech) {
            const win_by = this.parent.get_setting({ setting_name: 'win_by' });
            const match_options = this.parent.get_setting({ setting_name: 'match_options' });
            const game_options = this.parent.get_setting({ setting_name: 'game_options' });
            const points_per_game = this.parent.get_setting({ setting_name: 'points_per_game' });
            const point_1 = this.team_1_score.value === 0 ? 'Zero' : this.team_1_score.value;
            const point_2 = this.team_2_score.value === 0 ? 'Zero' : this.team_2_score.value;
            const serving_player_1 = this.parent.state.serving.side_state_1.serving_side;
            const serving_player_2 = this.parent.state.serving.side_state_2.serving_side;
            const call_side = this.parent.state.serving.side;
            const serving_side = game_options.value === 1 && this.parent.state.serving.type === 'doubles' ? call_side === 1 ? serving_player_1 : serving_player_2 : '';

            // serving_side
            // console.log('serving_player_1: ', serving_player_1);
            // console.log('serving_player_2: ', serving_player_2);
            // console.log('serving_side: ', serving_side);
            // Call Side needs to be called for the new server. if Side out.... it will be the Scorers side.
            // If Doubles and switching from server 1 to server 2 or vice versa, Call from the Servers Side.
            // Hide Doubles Helper for the team not sering

            let text = call_side === 1 ? point_1 + '!  ' + point_2 + '! ' + serving_side : point_2 + '!  ' + point_1 + '! ' + serving_side;
            if (point_1 === point_2) { text = point_1 + ' all' + ' ' + serving_side; }

            if (handout) { speech(speech_translate('handout')); }
            if (handout) {
               speech(text, false);
            } else {
               speech(text);
            }

            if (!this.won) {
               const pre_win_condition = this.pre_win_condition();
               if (pre_win_condition) {
                  const pre_match_win_condition = this.pre_match_win_condition();
                  const score_tally = this.get_score_tally;
                  if (((side === 1 && pre_win_condition === 1 && score_tally.team1 >= score_tally.team2) || (side === 2 && pre_win_condition === 2 && score_tally.team2 >= score_tally.team1)) && pre_match_win_condition) {
                     // speech("Match Ball");
                     speech(speech_translate('match_ball'));
                  } else if (pre_match_win_condition && (((side === 2 && score_tally.team2 <= score_tally.team1) && pre_win_condition === 1) || ((side === 1 && score_tally.team1 <= score_tally.team2) && pre_win_condition === 2))) {
                     speech(speech_translate('match_ball'));
                  } else if ((call_side === 1 && pre_win_condition === 1) || (call_side === 2 && pre_win_condition === 2)) {
                     speech(speech_translate('game_ball'));
                  }
               }
            }

            if (typeof match_options != 'undefined' && match_options != 2) {
               if (typeof this.parent.state.setup_config.players_to_win_by !== 'undefined' && this.parent.state.setup_config.players_to_win_by.value === true) {
                  const playerWinByCall = point_1 >= points_per_game.value - 1 && point_2 >= points_per_game.value - 1 ? true : false;
                  if (playerWinByCall) {
                     speech(speech_translate('win_by_call', win_by.value));
                     this.parent.state.setup_config.players_to_win_by.value = false;
                  }
               }
            }
         }

      } catch (error) {
         console.log('DEBUG - Score_Pickleball - call_score - error: ', error);
      }
   }
}

class Score {
   value = 0;
   add = (value) => { this.value += value; }
   sub = (value) => { this.value -= value; }
}

class Game_Log {
   action = '';
   content = '';
   score_side = 0;
   server_side = 0;
   server_player = 0;
   serving_side_1 = 0;
   serving_side_2 = 0;
   server_inner_side = 0;
   timestamp = '';
   ml_id = 0;
   synced = 0;
   data = null;


   constructor({ action, content, score_side, server_side, server_player, server_inner_side, serving_side_1, serving_side_2, timestamp, ml_id, synced, data = null }) {
      if (typeof data !== 'undefined') { this.data = data; }
      if (typeof synced !== 'undefined') { this.synced = synced; }
      if (typeof ml_id !== 'undefined') { this.ml_id = ml_id; }
      if (typeof action !== 'undefined') { this.action = action; }
      if (typeof content !== 'undefined') { this.content = content; }
      if (typeof score_side !== 'undefined') { this.score_side = score_side; }
      if (typeof server_side !== 'undefined') { this.server_side = server_side; }
      if (typeof server_player !== 'undefined') { this.server_player = server_player; }
      if (typeof serving_side_1 !== 'undefined') { this.serving_side_1 = serving_side_1; }
      if (typeof serving_side_2 !== 'undefined') { this.serving_side_2 = serving_side_2; }
      if (typeof server_inner_side !== 'undefined') { this.server_inner_side = server_inner_side; }
      if (typeof timestamp !== 'undefined') { this.timestamp = timestamp; }
      else {
         this.timestamp = Timestamp();
      }
   }
}

class Game {
   team_1_score = 0;
   team_2_score = 0;
   start_time = null;
   end_time = null;
   state = 'init';
   winner = '';
   logs = [];
   mrid = 0;
   synced = 0;

   constructor(args = {}) {
      this.start_time = Timestamp();
      if (typeof args.mrid !== 'undefined') { this.mrid = args.mrid; }
      if (typeof args.state !== 'undefined') { this.state = args.state; }
      if (typeof args.synced !== 'undefined') { this.synced = args.synced; }
      if (typeof args.winner !== 'undefined') { this.winner = args.winner; }
      if (typeof args.end_time !== 'undefined') { this.end_time = args.end_time; }
      if (typeof args.start_time !== 'undefined') { this.start_time = args.start_time; }
      if (typeof args.team_1_score !== 'undefined') { this.team_1_score = args.team_1_score; }
      if (typeof args.team_2_score !== 'undefined') { this.team_2_score = args.team_2_score; }

      if (typeof args.logs !== 'undefined') {
         args.logs.forEach(log => this.add_log(log));
      }
   }

   add_log = (args = {}) => { this.logs.push(new Game_Log(args)); }
   remove_log_last = () => { return this.logs.pop(); }
   get_log_last = () => {
      if (this.logs.length > 0) {
         return JSON.parse(JSON.stringify(this.logs[this.logs.length - 1]));
      } else {
         return false;
      }
   }

   get_total_game_logs = () => {
      return JSON.parse(JSON.stringify(this.logs.length));
   }

   // @param state: int
   /*
      0 === init
      1 === running
      3 === complete
   */
   set_state = (state) => {
      if (state === 0) {
         this.state = 'init';
      } else if (state === 1) {
         this.state = 'running';
      } else if (state === 2) {
         this.state = 'complete';
         this.end_time = Timestamp();
      }
   }
}

class Score_Settings {
   side = 2;
   state = {};
   scoring = {};
   serving = {};
   settings = {};
   serving_type = null;
   scoring_type = 'standard';

   constructor(args = {}) {
      // console.log('DEBUG - Score_Settings - constructor - args.settings: ', args.settings);
      if (typeof args.side !== 'undefined') { this.side = args.side; }
      if (typeof args.state !== 'undefined') { this.state = args.state; }
      if (typeof args.settings !== 'undefined') { this.settings = args.settings; }
      if (typeof args.scoring_type !== 'undefined') { this.scoring_type = args.scoring_type; }
      if (typeof args.serving_type !== 'undefined') { this.serving_type = args.serving_type; }
      this.scoring = convert_score_action(this.scoring_type, this);
      this.serving = convert_serving_action(this.serving_type, this, args.serving_config);
   }

   get_setting = ({ setting_name }) => {
      // console.log('DEBUG - Score_Settings - get_setting - settings: ', this.settings);
      const index = this.settings.findIndex(item => item.name === setting_name);
      if (index > -1) { return this.settings[index]; }
      return false;
   }
}

class Clock_Settings {
   id = null;
   value = 0;
   trigger = 1;
   action = null;
   reverse = false;
   timestamp = null;
   init_value = null;
   state = 0; // 0 === started - 1 === stopped

   constructor(args = {}) {
      if (typeof args.id !== 'undefined') { this.id = args.id; }
      if (typeof args.state !== 'undefined') { this.state = args.state; }
      if (typeof args.value !== 'undefined') { this.value = args.value; }
      if (typeof args.action !== 'undefined') { this.action = args.action; }
      if (typeof args.reverse !== 'undefined') { this.reverse = args.reverse; }
      if (typeof args.trigger !== 'undefined') { this.trigger = args.trigger; }
      if (typeof args.timestamp !== 'undefined') { this.timestamp = args.timestamp; }
      if (typeof args.init_value !== 'undefined') { this.init_value = args.init_value; }
   }
}

class Push_Match {
   t_id = 0;
   tc_id = 0;
   load_mid = 0;
   group_name = '';
   match_type = '';

   constructor(args = {}) {
      if (typeof args.t_id !== 'undefined') { this.t_id = args.t_id; }
      if (typeof args.tc_id !== 'undefined') { this.tc_id = args.tc_id; }
      if (typeof args.load_mid !== 'undefined') { this.load_mid = args.load_mid; }
      if (typeof args.group_name !== 'undefined') { this.group_name = args.group_name; }
      if (typeof args.match_type !== 'undefined') { this.match_type = args.match_type; }
   }
}

class Match {
   id = 0;
   sport = ''
   winner = '';
   match_code = '';
   match_state = '';
   settings = {};
   players;
   games = [];
   synced = 0;
   sync_error = 0;
   mid = 0;
   push_match = {};
   uuid = '';

   constructor(args = {}) {
      this.players = new Match_Players();
      if (typeof args.id !== 'undefined') { this.id = args.id; }
      if (typeof args.mid !== 'undefined') { this.mid = args.mid; }
      if (typeof args.uuid !== 'undefined') { this.uuid = args.uuid; }
      if (typeof args.sport !== 'undefined') { this.sport = args.sport; }
      if (typeof args.winner !== 'undefined') { this.winner = args.winner; }
      if (typeof args.synced !== 'undefined') { this.synced = args.synced; }
      if (typeof args.sync_error !== 'undefined') { this.sync_error = args.sync_error; }
      if (typeof args.settings !== 'undefined') { this.settings = args.settings; }
      if (typeof args.match_code !== 'undefined') { this.match_code = args.match_code; }
      if (typeof args.match_state !== 'undefined') { this.match_state = args.match_state; }
      if (typeof args.push_match !== 'undefined') { this.push_match = new Push_Match(args.push_match); }
      if (typeof args.players !== 'undefined') { this.players = new Match_Players(args.players); }
      if (typeof args.games !== 'undefined') {
         args.games.forEach(game => { this.add_game(game); });
      }

      if (this.games.length === 0) { this.add_game(); }
      if (this.uuid === '') {
         this.uuid = window.crypto.randomUUID();
      }
   }

   add_game = (args = {}) => {
      if (this.games.length > 0) {
         if (Object.keys(args).length === 0 && this.games[this.games.length - 1].winner === '') {
            console.log('RETURN');
            return
         }
      }

      this.games.push(new Game(args));
   }

   update_active_game = ({ team_1_score, team_2_score, start_time, end_time, state, logData, match_state, winner, match_winner, synced, mid }) => {
      if (typeof synced !== 'undefined') { this.synced = synced; }
      if (typeof mid !== 'undefined') { this.mid = mid; }
      if (typeof match_winner !== 'undefined') { this.winner = match_winner; }
      if (typeof match_state !== 'undefined') { this.match_state = match_state; }
      if (typeof state !== 'undefined') { this.games[this.games.length - 1].state = state; }
      if (typeof winner !== 'undefined') { this.games[this.games.length - 1].winner = winner; }
      if (typeof end_time !== 'undefined') { this.games[this.games.length - 1].end_time = end_time; }
      // if (typeof game_state !== 'undefined') {  this.games[this.games.length - 1].state = game_state; }
      if (typeof start_time !== 'undefined') { this.games[this.games.length - 1].start_time = start_time; }
      if (typeof team_1_score !== 'undefined') { this.games[this.games.length - 1].team_1_score = team_1_score; }
      if (typeof team_2_score !== 'undefined') { this.games[this.games.length - 1].team_2_score = team_2_score; }
      if (typeof logData !== 'undefined') { this.games[this.games.length - 1].add_log(logData); }
   }


   get active_game() {
      return this.games[this.games.length - 1];
   }

   get match_score() {
      const result = [];
      for (let a = 0; a < this.games.length; a++) {
         // TODO -- This needs to be a proper win Function, Example is game defaulted the winner could be the player with the lowest score...
         const win = this.games[a].team_1_score > this.games[a].team_2_score ? 1 : 2;
         result.push({
            A: this.games[a].team_1_score,
            B: this.games[a].team_2_score,
            Win: win,
         });
      }
      return result;
   }

   get match_score_jsx() {
      let result = [];
      for (let a = 0; a < this.games.length; a++) {
         if (this.games[a].winner === 1) {
            result.push((<div key={genKeyRan('s')} > <b style={{ color: this.players.teams[0].team_color }}>{this.games[a].team_1_score}</b> / {this.games[a].team_2_score} </div>))
         } else if (this.games[a].winner === 2) {
            result.push((<div key={genKeyRan('s')}> {this.games[a].team_1_score} / <b style={{ color: this.players.teams[1].team_color }}>{this.games[a].team_2_score}</b> </div>))
         } else {
            result.push((<div key={genKeyRan('s')}> {this.games[a].team_1_score} / {this.games[a].team_2_score} </div>))
         }
         if (a !== this.games.length - 1) {
            result.push((<div key={genKeyRan('s')} > ,&nbsp;&nbsp;   </div>))
         }
      }
      return (<div style={{ display: "flex", justifyContent: "center" }}> {result} </div>);
   }

   get match_score_string() {
      let result = '';
      for (let a = 0; a < this.games.length; a++) {
         if (this.games[a].team_1_score > this.games[a].team_2_score) {
            result += `${this.games[a].team_1_score} / ${this.games[a].team_2_score}`;
         } else if (this.games[a].team_1_score < this.games[a].team_2_score) {
            result += `${this.games[a].team_1_score} / ${this.games[a].team_2_score}`;
         } else {
            result += `${this.games[a].team_1_score} / ${this.games[a].team_2_score}`;
         }
         if (a !== this.games.length - 1) {
            result += ' , ';
         }
      }
      return result;
   }
}


class Side_State {
   serving_player = 1;
   serving_side = 2; // Used for Pickleball only so far -- Determines who is serving from the left;

   constructor(args = {}) {
      if (typeof args.serving_player !== 'undefined') { this.serving_player = args.serving_player; }
      if (typeof args.serving_side !== 'undefined') { this.serving_side = args.serving_side; }
   }

   // TODO: Move Player Defaults here....
}

class Serving_State {
   // parent;
   side = 0;
   inner_side = 0;
   player = 1;
   type = 'singles';
   settings = {};
   side_state_1;
   side_state_2;

   manual_change = false;
   manual_change_log = [];
   player_defaults = {
      1: null,
      2: null,
      3: null,
      4: null,
   }

   constructor(args = {}) {
      // console.log('DEBUG - Serving_State - constructor - args: ', args);
      if (typeof args.side !== 'undefined') { this.side = args.side; }
      if (typeof args.type !== 'undefined') { this.type = args.type; }
      if (typeof args.player !== 'undefined') { this.player = args.player; }
      if (typeof args.settings !== 'undefined') { this.settings = args.settings; }
      if (typeof args.inner_side !== 'undefined') { this.inner_side = args.inner_side; }
      if (typeof args.manual_change !== 'undefined') { this.manual_change = args.manual_change; }
      if (typeof args.manual_change_log !== 'undefined') { this.manual_change_log = args.manual_change_log; }
      if (typeof args.player_defaults !== 'undefined') { this.player_defaults = args.player_defaults; }
      if (typeof args.side_state_1 !== 'undefined') { this.side_state_1 = new Side_State(args.side_state_1); }
      else { this.side_state_1 = new Side_State(); }
      if (typeof args.side_state_2 !== 'undefined') { this.side_state_2 = new Side_State(args.side_state_2); }
      else { this.side_state_2 = new Side_State({ serving_player: 1 }); } // TODO: Set this Default to 2 when Doubles
      if (typeof args.parent !== 'undefined') {
         // this.parent = args.parent; 

         // eslint-disable-next-line no-undef
         this.parent_ref = new WeakRef(args.parent);
      }
   }

   get parent() {
      return this.parent_ref.deref();
   }

   updateSettings = (args = {}) => {
      for (const key in args) {
         if (Object.hasOwnProperty.call(args, key)) {
            this.settings[key] = args[key];
         }
      }
   }

   refresh_player = () => { }

   // For Manual Change
   updatePlayer = (player) => {
      // console.log('DEBUG - Serving_State - updatePlayer - player: ', this);

      this.player = player;

      if (player === 1) {
         this.side_state_1.serving_player = 1;
      } else if (player === 2) {
         this.side_state_2.serving_player = 1;
      } else if (player === 3) {
         this.side_state_1.serving_player = 2;
      } else if (player === 4) {
         this.side_state_2.serving_player = 2;
      }

      if (player === 1 || player === 3) {
         this.side = 1;
      } else if (player === 2 || player === 4) {
         this.side = 2;
      }
   }

   // For Manual Change
   updateServer = (side, inner_side) => {
      if (this.type === 'singles') {
         this.player = side;
      } else {
         if (this.player < 3) {
            this.player = side;
         } else {
            if (side === 1) {
               this.player = 3;
            } else if (side === 2) {
               this.player = 4;
            }
         }
      }

      this.side = side;
      this.inner_side = inner_side;
      this.manual_change = true;

      const index = this.manual_change_log.findIndex(log => log.player === this.player);
      if (index !== -1) { this.manual_change_log.splice(index, 1); }
      this.manual_change_log.push({
         player: this.player,
         inner_side: this.inner_side,
      });
   }

   test = () => {
      console.log('TEST: ', this);
   }
}

class Serving_State_Pickleball extends Serving_State {
   constructor(args = {}) {
      super(args);
   }

   refresh_player = () => {
      let player;
      if (this.side === 1 && this.side_state_1.serving_player === 1) {
         player = 1;
      } else if (this.side === 1 && this.side_state_1.serving_player === 2) {
         player = 3;
      } else if (this.side === 2 && this.side_state_2.serving_player === 1) {
         player = 2;
      } else if (this.side === 2 && this.side_state_2.serving_player === 2) {
         player = 4;
      }
      this.updatePlayer(player);
   }

   updatePlayer = (player) => {
      try {
         const game_options = this.parent.parent.get_setting({ setting_name: 'game_options' });

         if (game_options.value === 1 && this.type === 'doubles') {
            // const { team1, team2 } = this.parent.parent.scoring.get_score_tally;
            const total_logs = this.parent.match.active_game.get_total_game_logs();
            // console.log('total_logs: ', total_logs);
            // console.log('player: ', player);

            // if ((team1 + team2 === 0) && (player === 1 || player === 3)) {
            if (total_logs === 0 && (player === 1 || player === 3)) {
               this.side_state_1.serving_side = 2;
               this.side = 1;
               return;
               // } else if ((team1 + team2 === 0) && (player === 2 || player === 4)) {
            } else if (total_logs === 0 && (player === 2 || player === 4)) {
               this.side_state_2.serving_side = 2;
               this.side = 2;
               return;
            }
         }

         /// Code duplicate from super.updatePlayer
         this.player = player;
         if (player === 1) {
            this.side_state_1.serving_player = 1;
         } else if (player === 2) {
            this.side_state_2.serving_player = 1;
         } else if (player === 3) {
            this.side_state_1.serving_player = 2;
         } else if (player === 4) {
            this.side_state_2.serving_player = 2;
         }
         if (player === 1 || player === 3) {
            this.side = 1;
         } else if (player === 2 || player === 4) {
            this.side = 2;
         }
      } catch (error) {
         console.error('Serving_State_Pickleball - updatePlayer: ', error);
      }
   }



}

class Match_State {
   // parent;
   match;
   status = 'init';
   timestamp = null;
   setup_config = null;
   serving = {
      side: 0,
      inner_side: 0,
      player: 1,
      type: 'singles',
   };

   constructor(args = {}) { this.updateState(args); }

   updateState = (args = {}) => {
      this.match = new Match();
      // this.serving = new Serving_State();

      if (typeof args.parent !== 'undefined') {
         const serving_args = typeof args.serving !== 'undefined' ? args.serving : {
            parent: this,
         };
         this.serving = convert_serving_state(args.parent.serving_type, serving_args);
      }
      if (typeof args.serving_config !== 'undefined') {
         // if (typeof args.serving_config.player !== 'undefined') { this.serving.player = args.serving_config.player; }
         if (typeof args.serving_config.server_default !== 'undefined') { this.serving.side = args.serving_config.server_default.value; }
         if (typeof args.serving_config.handout_default !== 'undefined') { this.serving.inner_side = args.serving_config.handout_default.value; }
         this.serving.updateSettings(args.serving_config);
      }
      if (typeof args.setup_config !== 'undefined') { this.setup_config = args.setup_config; }
      if (typeof args.status !== 'undefined') { this.status = args.status; }
      if (typeof args.serving !== 'undefined') {
         args.serving.parent = this;
         this.serving = convert_serving_state(args.parent.serving_type, args.serving);
      }
      if (typeof args.player !== 'undefined') { this.serving.player = args.player; }
      if (typeof args.timestamp !== 'undefined') { this.timestamp = args.timestamp; }
      if (typeof args.matchType !== 'undefined') { this.serving.type = args.matchType; }
      // if (typeof args.color_table !== 'undefined') { this.color_table = args.color_table; }
      if (typeof args.match !== 'undefined') { this.match = new Match(args.match); }
      if (typeof args.parent !== 'undefined') {
         // this.parent = args.parent; 

         // eslint-disable-next-line no-undef
         this.parent_ref = new WeakRef(args.parent);
      }
   }

   get parent() {
      return this.parent_ref.deref();
   }
}



class Warmup_State {
   state = '';
}

class Between_Games_State {
   setup;
   tool_block;

   constructor(args = {}) {
      if (typeof args.setup !== 'undefined') {
         const newSetup = {};
         for (let a = 0; a < args.setup.length; a++) {
            for (const key in args.setup[a]) {
               if (Object.hasOwnProperty.call(args.setup[a], key)) {
                  newSetup[key] = args.setup[a][key];
               }
            }
         }
         this.setup = newSetup;
      }
   }

}

class Player {
   id = 0;
   display_name = 'Player';
   first_name = '';
   last_name = '';
   color = '';
   img = '';

   constructor(args = {}) {
      if (typeof args.display_name !== 'undefined') {
         this.display_name = args.display_name;
      }
      if (typeof args.color !== 'undefined') {
         this.color = args.color;
      }
      if (typeof args.img !== 'undefined') {
         this.img = args.img;
      }
      if (typeof args.id !== 'undefined') {
         this.id = args.id;
      }
      if (typeof args.first_name !== 'undefined') {
         this.first_name = args.first_name;
      }
      if (typeof args.last_name !== 'undefined') {
         this.last_name = args.last_name;
      }
   }


   get initial() {
      let initial = '';
      if (this.display_name === 'Player A') { initial = 'A'; }
      else if (this.display_name === 'Player B') { initial = 'B'; }
      else if (this.display_name === 'Player C') { initial = 'C'; }
      else if (this.display_name === 'Player D') { initial = 'D'; }
      else { initial = this.display_name.trim().substring(0, 1).toUpperCase(); }
      return initial;
   }
}

class Teams {
   // name = '';
   players = [];

   constructor(args = {}) { // constructor
      // if (typeof args.name !== 'undefined') {
      //    this.name = args.name
      // }
      if (typeof args.players !== 'undefined') {
         for (let a = 0; a < args.players.length; a++) {
            this.players.push(new Player(args.players[a]));
         }
      }
   }

   get team_color() {
      let color = '#FFF';
      if (this.players.length > 0) {
         color = this.players[0].color;
      }
      return color;
   }

   get name() {
      let team_name = '';
      if (this.players.length > 0) {
         if (this.players.length > 1) {
            const p1 = this.players[0].display_name.substring(0, 7);
            const p2 = this.players[1].display_name.substring(0, 7);
            team_name = p1 + ' / ' + p2;
         } else {
            team_name = this.players[0].display_name.substring(0, 12);
         }
      }
      return team_name;
   }
}

class Match_Players {
   sides = 2;
   teams = [];
   constructor(args = {}) { // constructor
      if (typeof args.sides !== 'undefined') {
         this.sides = args.sides;
      }
      if (typeof args.teams !== 'undefined') {
         for (let a = 0; a < args.teams.length; a++) {
            this.teams.push(new Teams(args.teams[a]));
         }
      } else {
         for (let a = 0; a < this.sides; a++) {
            this.teams.push(new Teams());
         }
      }
   }

   get color_table() {
      let count = 0;
      let loop = true;
      const color_table = [];
      do {
         for (let a = 0; a < this.teams.length; a++) {
            if (typeof this.teams[a].players[count] !== 'undefined') {
               color_table.push(this.teams[a].players[count].color);
            } else {
               loop = false;
            }
         }
         count++;
         if (count > 10) { loop = false; } // Fail Safe -- Increase limit if teams are allowed to have more that 10 players
      } while (loop);
      return color_table;
   }

   // get team_a_name() {
   //    let name = '';
   //    console.log('Get team_a_name: ', this.teams)
   //    return name;
   // }
   // get team_b_name() {
   //    let name = '';
   //    console.log('Get team_b_name: ', this.teams)
   //    return name;
   // }

}

class SportSetting {
   name;
   fun;
   label;
   type;
   value;
   sub_value;
   placeholder;
   // parent;
   dep;
   jsx;
   link;
   data_link;
   data_type;
   special_type;

   constructor(args = {}) { // constructor
      this.dep = args.dep;
      this.fun = args.fun;
      this.type = args.type;
      this.name = args.name;
      this.link = args.link;
      this.label = args.label;
      this.hidden = args.hidden;
      this.value = args.value;
      // this.parent = args.parent;

      // eslint-disable-next-line no-undef
      this.parent_ref = new WeakRef(args.parent);
      this.options = args.options;
      this.callback = args.callback;
      this.data_link = args.data_link;
      this.data_type = args.data_type;
      this.sub_value = args.sub_value;
      this.placeholder = args.placeholder;
      this.special_type = args.special_type;
      if (typeof args.jsx != 'undefined') {
         this.jsx = string_to_class(args.jsx, { parent: this });
      } else {
         this.jsx = new SportSettingElement();
      }
   }

   get parent() {
      return this.parent_ref.deref();
   }
}

class SportSettingElement {
   // parent;
   constructor(args = {}) {
      // this.parent = args.parent; 

      // eslint-disable-next-line no-undef
      this.parent_ref = new WeakRef(args.parent);
   }

   get parent() {
      return this.parent_ref.deref();
   }

   build_jsx = () => { return (''); }
   // build_jsx = () => { return (<> NOT SET </>); }
}

class PlayerSettings extends SportSettingElement {
   constructor(args = {}) { super(args); }
   build_jsx = () => {
      const name = this.parent.name;
      const label = this.parent.label;
      const value = this.parent.value;
      const hidden = this.parent.hidden;
      const placeholder = this.parent.placeholder;
      const defaultColor = this.parent.sub_value.value;
      const colorName = this.parent.sub_value.name;
      // console.log('colorName: ', colorName);
      return (<InputWrap hidden={hidden} key={name} label={label}><Input type="text" name={name} value={value} placeholder={placeholder}><ColorBox defaultColor={defaultColor} name={colorName}></ColorBox></Input></InputWrap>);
   }
}

class RadioSetting extends SportSettingElement {
   constructor(args = {}) { super(args); }
   build_jsx = () => {
      const name = this.parent.name;
      const label = this.parent.label;
      const value = this.parent.value;
      const options = this.parent.options;
      const hidden = this.parent.hidden;
      return (<InputWrap hidden={hidden} key={name} label={label}> <RadioSelect options={options} value={value} name={name} ></RadioSelect> </InputWrap>);
   }
}

class InputCountSetting extends SportSettingElement {
   constructor(args = {}) { super(args); }
   build_jsx = () => {
      const name = this.parent.name;
      const label = this.parent.label;
      const value = this.parent.value;
      const hidden = this.parent.hidden;
      return (<InputWrap hidden={hidden} key={name} label={label}> <InputCounter type="number" name={name} value={value} ></InputCounter> </InputWrap>);
   }
}

class ActionLink {
   text;
   icon;
   type;

   constructor(args = {}) {
      console.log('>>> ActionLink <<<');
      console.log('args: ', args.text);

      this.text = args.text;
      this.icon = args.icon;
      this.type = args.type;
   }


   build_jsx = () => {
      console.log('>>> Action build_jsx: ')
      // const name = this.parent.name;
      // const label = this.parent.label;
      // const value = this.parent.value;
      // return (<InputWrap key={name} label={label}> <InputCounter type="number" name={name} value={value} ></InputCounter> </InputWrap>);
      return (<> {this.text} </>);
   }

}




class HotKeyKeys {
   action = '';
   key = '';

   constructor(args = {}) {
      this.action = args.action;
      this.key = args.key;
   }
}


class HotKeyAction {
   screen = null;
   condition = null;
   fun = [];
   keys = [];

   constructor(args = {}) {
      this.screen = args.screen;
      this.condition = args.condition;
      this.fun = args.fun;
      this.keys = args.keys;
   }
}

class HotKeyController {
   active = false;
   registered_keys = [];
   registered_actions = [];
   event;
   sport_object;

   constructor(args = {}) {
      this.sport_object = args.sport_object;
   }

   start_listener = () => { this.event_listener(); }
   stop_listener = () => { console.log('>>> stop_listener: '); }

   event_listener = () => {
      console.log('>>> HotKeyController event_listener: ');
      document.addEventListener('keydown', (e) => {
         if (!this.debounceTimer) {
            this.debounceTimer = setTimeout(() => {
               this.registered_keys.forEach(hotkey => {
                  if (hotkey.key === e.key) {
                     this.check_hotkey(hotkey.action);
                     // e.preventDefault();
                     // e.stopPropagation();
                  }
               });
               this.debounceTimer = null;
            }, 350);
         }
         // this.registered_keys.forEach(hotkey => {
         //    if (hotkey.key === e.key) {
         //       this.check_hotkey(hotkey.action);
         //    }
         // });
      }, true);
   }

   register_hotkey_keys = (keys = []) => {
      keys.forEach(keyObj => {
         keyObj.keys.forEach(key => {
            this.registered_keys.push(new HotKeyKeys({ key: key, action: keyObj.action }));
         });
      });
   }

   register_hotkey_actions = (actions = []) => {
      actions.forEach(actionObj => {
         this.registered_actions.push(new HotKeyAction({
            screen: actionObj.screen,
            condition: actionObj.condition,
            fun: actionObj.fun,
            keys: actionObj.keys,
         }));
      });
   }

   check_screen = () => {
      if (IsSwalOpen()) { return 'confirm_box'; }
      switch (this.sport_object.app_state.game_state) {
         case 'warmup':
            return 'warm_up_screen';
         case 'server_select':
            return 'serving_selector_confirm_box';
         case 'running':
            return 'scoring_screen';
         case 'game_complete':
            return 'between_game_screen';
         case 'match_complete':
            return 'match_complete_screen';
         default:
            return null;
      }
   }

   check_condition = (condition) => {
      return new Promise(async (resolve) => {
         // console.log('>>> check_condition: ', condition);
         if (condition === null) {
            resolve(true);
         } else {
            const result = await this.sport_object.check_dependencies(condition);
            resolve(result);
         }
      });
   }

   check_hotkey = async (hotkey) => {
      // console.warn('>>> check_hotkey: ', hotkey);
      if (this.sport_object.app_state.app_state === 'match') {
         const screen = this.check_screen();
         for (let a = 0; a < this.registered_actions.length; a++) {
            if (this.registered_actions[a].screen === screen) {
               for (let b = 0; b < this.registered_actions[a].keys.length; b++) {
                  if (this.registered_actions[a].keys[b] === hotkey) {
                     // console.warn('check_hotkey: ', this.registered_actions[a].fun[0].type);
                     if (await this.check_condition(this.registered_actions[a].condition)) {
                        this.trigger_hotkey(this.registered_actions[a].fun);
                     }
                  }
               }
            }
         }
      } else {
         console.error('check_hotkey failed, no match running');
      }
   }

   trigger_hotkey = async (fun) => {
      // console.log('>>> trigger_hotkey: ', fun);

      for (let a = 0; a < fun.length; a++) {
         console.log('Function: ', fun[a].type);
      }

      const sport_function = build_sport_function(fun);
      await sport_function();
   }
}


export {
   Sport,
   Teams,
   Match,
   Player,
   ActionLink,
   Push_Match,
   Match_State,
   SportSetting,
   Warmup_State,
   RadioSetting,
   Match_Players,
   Clock_Settings,
   Score_Settings,
   PlayerSettings,
   Between_Games_State,
   HotKeyController,
   string_to_icon,
}
export default Sport;