import React from 'react';
import logo from '../../images/sport/generic/Logo.svg';
import logo_squash from '../../images/sport/squash/logo.png';
import logo_pickleball from '../../images/sport/pickleball/logo.png';

function LogoImg(Props) {
   const sport = Props.sport;
   let logo_image = logo;
   if (sport === 'Squash') {
      logo_image = logo_squash;
   } else if (sport === 'Pickleball') {
      logo_image = logo_pickleball;
   }

   return (
      <img src={logo_image} alt="logo"></img>
   );
}

export default LogoImg;